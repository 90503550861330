import React, { useState, useEffect, useContext }  from 'react';
import MediaQuery from "react-responsive/src";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cn from 'classnames';
import {ScrollListView} from '../../../components/ScrollListView';
import {GraniteTextureCard} from './GraniteTextureCard';
import {AccessoryGranite} from './AccessoryGranite';
import * as _ from 'lodash';
import {LazyImage} from '../../../components/LazyImage';
import {getStore} from '../../../store';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {userCan, getOrganization} from '../../../services/userRights.service';
import { TranslationContext } from "../../../context/TranslationContext";

/**
 * Contenu d'une modale générique permettant de choisir un granite.
 *
 * Si suggestionImageURL est passé, propose en suggestions le noir fin,
 * le granite primaire (et secondaire) du monument, et le granite actuel.
 *
 * Ces suggestions sont rendues avec des cards, dont l'url de
 * l'image est générée par suggestionImageURL.
 *
 */
function GranitePickerComponent({
  // Requis
  onPickGraniteRef,
  activeTabParams,
  // Optionnel
  currentGraniteRef, // Si vide, pas de granite selectionné
  title, // Si vide, pas de header affiché
  suggestionImageURL, // Si vide, pas de suggestions
  customGranites, // Si vide, on propose tous les granites
  family,
  activeTabIndex,

  // Résultat du connect
  monumentGraniteMain,
  monumentGraniteSecondary,
  allGranites,
  adminConfig,
  modalSettings,
  accessoryRef = null,
  accessories,
  productFrom
}) {
  const t = useContext(TranslationContext);
  const modifyGraniteNames = (granites) => {
    if(!granites) return;
    return granites.map((granite) => {
      const modifiedName = !family ? t(`config3d_granit_${granite.reference}`) : granite.name;
      return { ...granite, name: modifiedName };
    });
  };

  customGranites = modifyGraniteNames(customGranites);
  allGranites = modifyGraniteNames(allGranites);

  const [isallgranites, setIsallgranites] = useState(false);
  
  let granites = customGranites || _.filter(allGranites, 'available');

  if(customGranites){
    if ((modalSettings && modalSettings.reference && modalSettings.reference.startsWith("ACI")) || (activeTabParams && activeTabParams.reference && activeTabParams.reference.startsWith("ACI")))
      granites = _.filter(customGranites)
    else
      granites = _.filter(allGranites).filter(granite => customGranites && customGranites.map(granite => granite.reference).includes(granite.reference))
  }
  granites = granites.filter((a, i) => granites.findIndex((b) => a.reference === b.reference) === i)
  // Si des granits sont interdits via l'admin on les filtres
  const monumentComponents = ["PLT","SEM","ACC"];
  const monument = getStore().getState().configurator.current.configuration.monument;
  if(monument.category=="PLT" || monument.category==="SEM"){
    granites = customGranites || _.filter(allGranites);
    let granitesComp = monument.category === "PLT" ? getStore().getState().configurator.current.veneerGranite : getStore().getState().configurator.current.frameGranite;
    let res = granites.filter(granite=> granitesComp.includes(granite.reference) && granite.colorGranite);
    granites = res;
  }
  // Si en configure un accessoire du catalog stock
  if(monument.category == "ACC" && productFrom === "stocks") {
    const ref = activeTabParams?.reference || modalSettings?.reference    
    const activeAcc = _.find(accessories, acc => (acc?.reference == ref))
    if(activeAcc?.granites) granites = activeAcc?.granites
  }

  granites = _.filter(
    granites,
    graniteRef => {
      let graniteList = graniteRef.catalogs && graniteRef.catalogs.split(/[;,]/) || []
      const organizationCatalog = getOrganization().catalogs && getOrganization().catalogs.split(/[;,]/) || []
      if(organizationCatalog.length === 0 || graniteList.length === 0 ) return false 
      for(let i=0; i<graniteList.length; i++) {
        if (organizationCatalog.indexOf(graniteList[i]) !== -1) {
          return true
        }
      }
      return false
    }

  )
  
  // Si des granits sont interdits via l'admin on les filtres
  
  if (adminConfig && adminConfig.granitesToHide && adminConfig.granitesToHide.length >= 0){

    const hideGranites= adminConfig.granitesToHide.map(granite=>(granite.value));
    granites = granites.filter(granite=> !hideGranites.some(hideGranite=>hideGranite === granite.reference));
  }

  // On ne peut pas sélectionner un granit made in france pour semelle/placage/accessoire
  // si le granit du monument n'est pas aussi made in france. Et inversement.
  // On crée donc une liste des granites désactivés.
  const fullMonumentGranite = _.find(allGranites, {reference: monumentGraniteMain});
  const isMonumentMadeInFrance = fullMonumentGranite && fullMonumentGranite.madeInFrance;
  let disabledGranites = _.chain(granites)
    .reject({madeInFrance: isMonumentMadeInFrance})
    .reject({madeInFranceAndImported: true})
    .map('reference')
    .value();
  if(monumentComponents.includes(monument.category)){
    disabledGranites = []
  }

  if(granites.length > granites.filter(granite => granite.poids <= granite.maxpoids).length && !isallgranites){
    granites.push({reference: 0,poids: 0, maxpoids: granites[0].maxpoids}) 
  }
  

  const suggestionsHeader = suggestionImageURL &&
    _.chain([
      monumentGraniteMain, // Toujours le granit principal en premier
      monumentGraniteSecondary,
      'NF',
      currentGraniteRef,
    ])
      .uniq()
      .map(reference=>_.find(granites, {reference}))
      .filter()
      .map(granite=> {
        console.log("granite", granite)
        return (
        <div key={granite.reference} className="ListViewItem">
          <div className="ListViewItemContainer">
            <GranitePreviewSuggestionCard
              key={granite.reference}
              granite={granite}
              selected={granite.reference === (currentGraniteRef || monumentGraniteMain)}
              disabled={disabledGranites.indexOf(granite.reference) >= 0}
              onSelectGranite={onPickGraniteRef}
              src={suggestionImageURL(granite.reference)}/>
          </div>
        </div>
        )
      }
    )
  .thru(cards=> <SuggestionHeader>{cards}</SuggestionHeader>)
  .value();

  function renderGraniteTextureCard(granite) {
    if(granite.reference === 0 && (this && this.props && !this.props.family)){
      return (
        <a onClick={() => setIsallgranites(true)}
          className={cn(['GraniteCard', 'GraniteTextureCard'])} style={{background:"#7f7f7f"}}
        >
            <div className="DisabledMessage plusGranite" >
            <div><div>+</div> <div >Voir plus de granits</div></div>

            </div>
        </a>
      );
    }
    return accessoryRef ? (
      <div className="accessory-wrapper">
        <AccessoryGranite
          key={granite.reference}
          granite={granite}
          selected={granite.reference === (currentGraniteRef || monumentGraniteMain)}
          disabled={disabledGranites.indexOf(granite.reference) >= 0}
          onSelectGranite={() => onPickGraniteRef(granite.reference)}
          accessoryRef={accessoryRef}
        />
      </div>
    ) : (
      <div className="granite-wrapper">
        <GranitePreviewSuggestionCard
          key={granite.reference}
          granite={granite}
          selected={granite.reference === (currentGraniteRef || monumentGraniteMain)}
          disabled={disabledGranites.indexOf(granite.reference) >= 0}
          src={suggestionImageURL(granite.reference)}
          onSelectGranite={() => onPickGraniteRef(granite.reference)}
        />

        {/* <GraniteTextureCard
          key={granite.reference}
          granite={granite}
          selected={granite.reference === (currentGraniteRef || monumentGraniteMain)}
          disabled={disabledGranites.indexOf(granite.reference) >= 0}
          onSelectGranite={() => onPickGraniteRef(granite.reference)}
        /> */}
      </div>
    );
  }

  // start filter functions of ReactSearchAutocomplete -------------------------------------------- 
  const [availableGranites, setAvailableGranites] = useState(
    granites.sort((a, b) => b.poids - a.poids).filter(granite => granite.poids <= granite.maxpoids)
  );

  const [searched, setSearched] = useState('');
  const [color, setColor] = useState('');


  const sortAndSelectGranite = (granitesList, currentGraniteRef, monumentGraniteMain) => {
    const sortedGranites = granitesList.sort((a, b) => b.poids - a.poids);
    const selectedGraniteIndex = sortedGranites.findIndex(granite => granite.reference === (currentGraniteRef || monumentGraniteMain));

    if (selectedGraniteIndex !== -1) {
      const selectedGranite = sortedGranites.splice(selectedGraniteIndex, 1)[0];
      return [selectedGranite, ...sortedGranites.filter(granite => granite.poids <= granite.maxpoids)];
    } else {
      return sortedGranites.filter(granite => granite.poids <= granite.maxpoids);
    }
  };

  useEffect(() => {
    setAvailableGranites(sortAndSelectGranite(granites, currentGraniteRef, monumentGraniteMain));
  }, [activeTabIndex]);
  
  const handleOnSearch = (string, results) => {
    setSearched( string );
  }

  const handleOnSelect = (item) => {
    // the item selected
    setColor( item.name );
    setSearched( item.name );
    setAvailableGranites( granites.filter(
      (granite) => granite.name && (granite.name.toUpperCase().includes(item.name.toUpperCase()) || cleanSentence(granite.name).includes(item.name.toUpperCase()))
    ))
  }

  const handleOnSelectColor = (item) => {
    const bicoloreMonument = getStore().getState().configurator.current.configuration.bicolorMonument
    
    setColor( item.name );
    setSearched(null);
    setAvailableGranites( granites.filter(
      (granite)=>{
        const thefilter = bicoloreMonument ? granite.colorGranite.toUpperCase().includes(`${item.name.toUpperCase()}`) : granite.colorGranite.toUpperCase().includes(item.name.toUpperCase())
        const thefilterSecond = bicoloreMonument ? cleanSentence(granite.colorGranite).includes(`${item.name.toUpperCase()}`) : cleanSentence(granite.colorGranite).includes(item.name.toUpperCase())
        return granite.colorGranite && (thefilter || thefilterSecond)
  }))
  }

  const handleOnClear = () => {
    setColor(null);
    setSearched(null);
    setAvailableGranites(sortAndSelectGranite(granites, currentGraniteRef, monumentGraniteMain));
  }
    
  // const onSearchGraniteBtn = () => {
  //   // get autocomplet input
  //   const inputFeild = document.querySelector('._FfilterGranit input').value;
  //   if ( inputFeild && inputFeild.length > 1 && searched && searched.length > 1) {
  //     setAvailableGranites(granites.filter(
  //         (granite) => granite.name && (granite.name.toUpperCase().includes(searched.toUpperCase()) || cleanSentence(granite.name).includes(searched.toUpperCase()))
  //       )
  //     );
  //   } else {
  //     setSearched( null );
  //     setAvailableGranites( granites.filter(granite => granite.poids <= granite.maxpoids ) );
  //   }
  // }

  const cleanSentence = (sentence) => {
    return sentence.toUpperCase().replace(/É|Ê|È|Ë/g, "E")
  }

  // Available Granites Names to filter
  let granitesNames = granites.filter(granite=> granite.name);
  granitesNames =  granitesNames.map((granite, index)  => ({ 
      id:index, 
      name:(granite.name.toUpperCase()), 
      name2:(cleanSentence(granite.name))
  }));
  granitesNames.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
  });

  const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
                    .substring(1).match(/.{2}/g)
                    .map(x => parseInt(x, 16))
                    
  let theColors = getStore().getState().configurator.current.options.granitcolors
  theColors && theColors.map(elt => {
    let colorinrgb = hexToRgb(elt.color)
    const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
    const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
    colorinrgb = colorinrgb.map(x => x - 75);
    const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
    elt.backcolor = "linear-gradient(0deg,"+color1+" 3%, "+color+" 40%, "+color2+" 90%)"
  })

  // End filter functions of ReactSearchAutocomplete -------------------------------------------- 
  return (
    <div>
      {!!granites.length && <div>
        {title &&
          <div className="Header">
            <div className="HeaderContainer">
              <h1 className="Headline">{title}</h1>
            </div>
          </div>
        }

        {!family && <div className='fGraniteColorsContainer'>
          <div className='FfilterCatalog GraniteColors colorsX'>
              <div className='fFilterGraniteContainer'>
                <div className="_FfilterGranit">
                  <div style={{ display:'flex' }}>
                    <div style={{ width: 250, display: "inline-block", "zIndex":9999 }}>
                      <ReactSearchAutocomplete
                        items={granitesNames}
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onClear={handleOnClear}
                        inputSearchString={searched || ''}
                        placeholder={ !family ? t('config3d_monument_modal_granit') : "GRANIT..." }
                        showIcon={true} 
                        styling={{
                          height: "31px", 
                          boxShadow: "none",
                          border: "1px solid #D1D5DB",
                          borderRadius: "7px"
                        }}
                        fuseOptions={{ caseSensitive: false , threshold: 0.2, sortFn: (a, b) => (a.name - b.name), minMatchCharLength: 2, keys: [ "name", "name2"] }}
                        autoFocus
                      />
                    </div>
                    {/* <button 
                      className="text-cta secondary1 custom-secondary-button" 
                      style={{margin: '0 12px'}} 
                      onClick={()=> onSearchGraniteBtn()}>
                        { !family ? t('config3d_monument_modal_search') : "Rechercher" } 
                    </button> */}
                  </div>
                </div> 

                <div className="flex-75 d-flex align-items-center flex-wrap justify-content-center">
                  {theColors && 
                    theColors.map(elt => 
                      <button key={elt.name} 
                        className="colorFilter extrColor"
                        style={{backgroundColor: elt.color}}
                        onClick={()=>handleOnSelectColor(elt)}
                        data-gtm={elt.name}>
                          {/* {elt.name || ''} */}
                      </button>
                    )
                  }
                  <button 
                    className="reset-filter text-cta secondary1 custom-secondary-button button-primary button-small text-cta"
                    onClick={()=>{ handleOnClear() }}> 
                    réinitialiser
                  </button>
                  <span className="tooltip top" tooltip-text={t("config3d_filterbar_granit_tooltip")} style={{paddingTop: '4px'}}>
                    <span className="infos-icon icon-large secondary2-icon"></span>
                  </span>
                </div>

              </div>
          </div>
        </div>}

        {!isallgranites && <ScrollListView items={family?[]:availableGranites}
          renderItem={renderGraniteTextureCard}
          className="GenericGranitePicker"
          scrollableHeader={suggestionsHeader}
          />
        }

        {isallgranites && <ScrollListView items={family?[]:granites}
          renderItem={renderGraniteTextureCard}
          className="GenericGranitePicker"
          scrollableHeader={suggestionsHeader}
          />
        }
      </div>}
      {!granites.length && <div className="NoGranit">Il n'y a pas d'autres granits disponibles</div>}
   </div>   
  )
}

function SuggestionHeader({children}) {
  const {family:isFamily} =  getStore().getState().user;
  const t = useContext(TranslationContext);
  return (
    <section>
      <h4>
        { !isFamily ? t('config3d_granit_modal_all_suggested') : "Granits suggérés" }
      </h4>
      <div className="SuggestedGranites">
        <div className="ListView">
          <div className={`ListViewContainer ${isFamily ? 'FamilyListViewContainer' : ''}`}>
            {children}
          </div>
        </div>
      </div>
      <h4>
        { !isFamily ? t('config3d_granit_modal_all_allgranitpossible') : "Tous les granits possibles" }
      </h4>
    </section>
  )
}

function GranitePreviewSuggestionCard({granite, onSelectGranite, src, selected, disabled}) {
  return (
    <div className={cn(['GraniteCard', 'GranitePreviewCard', {selected, disabled}])}
       onClick={() => !selected && !disabled && onSelectGranite(granite.reference)}>
      <LazyImage src={src} lazy={false}/>
      {disabled && (
        <div className="DisabledMessage">
          <p>Ce granit n'est pas compatible avec celui du monument</p>
        </div>
      )}
      <div className="Infos">
        <h3 className='Name'>
          {granite.name}
        </h3>
      </div>
    </div>
  )
}

GranitePickerComponent.propTypes = {
  // Configuration
  onPickGraniteRef: PropTypes.func.isRequired,
  currentGraniteRef: PropTypes.string,
  title: PropTypes.string,
  suggestionImageURL: PropTypes.func,
  customGranites: PropTypes.arrayOf(PropTypes.shape({
    reference: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })), // Remplace la liste complète des granites

  // Résultat du connect
  monumentGraniteMain: PropTypes.string.isRequired,
  allGranites: PropTypes.arrayOf(PropTypes.shape({
    reference: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export const GranitePicker = connect(state => ({
    monumentGraniteMain: state.configurator.current.configuration.monument.graniteMain,
    monumentGraniteSecondary: state.configurator.current.configuration.monument.graniteSecondary,
    allGranites: state.configurator.current.options.allgranites,
    accessories: state.configurator.accessories,
    productFrom: state.configurator.productFrom,
    adminConfig: state.adminConfig.config,
    modalSettings: state.ui.accessoryGraniteModalParameters
  })
)(GranitePickerComponent);
