import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as configurationActions from "../../../actions/configurationActions";
import * as _ from "lodash";
import { OptionPanel } from "../components/OptionPanel";
import {getStore} from '../../../store';
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";
import { replaceMultiplicationSymbol } from "../../../services/utils.service";

class LayoutPickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    layout: PropTypes.string.isRequired,
    layouts: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired
  };
    
  componentWillMount() {
    if (this.props.needFiltering) {
      this.filterLayouts();
    }
  }
  filterLayouts = () => {
    let found = false;
    let availablesLayouts = this.props.layouts.filter(layout => layout.available);
    const defaultLayout = this.props.layouts.find(layout=>layout.reference === this.props.layout);
    let defaultName = defaultLayout.name.replace("×",",");
    //On vérifie si la recette par défaut est dans les filtres si oui on la sélectionne
    if (this.props.filteredLayouts.find(layout=>layout === defaultName)){
      found = availablesLayouts.find(layout =>layout.reference === defaultLayout.reference);
    }
    // Il faut filtrer les recettes à la première configuration on écarte les recettes
    // non présentes dans le filtre et on prend la première trouvée
    if (!found){
      this.props.filteredLayouts.forEach(layout => {
        if (!found){
          const tempLayout = layout.replace(",", "×");
          found =  availablesLayouts.find(layout =>layout.name === tempLayout);
        }
      });
    }
    this.props.configActions.filteredLayouts(false);

    if (found && this.props.layout !== found.reference){ // si la recette trouvée par filtrage n'est pas celle par défaut
    // on met à jour le back-end avec un petit timetout pour éviter les conflit de configuration.
      setTimeout(()=>{this.props.actions.updateLayout(found.reference)}, 120);
    }

    } 


  onSelectItem = layout => {
    this.props.actions.updateLayout(layout.reference); 
    if(layout.defaultFrameSizes !== undefined){
      this.props.actions.removeFrame();
    }
  };
  getLayoutSize = (layout,availablesLayouts)=>{

    const theLayout = availablesLayouts.filter(item=>item.reference === layout );

    return theLayout[0].name
  }
  sortLayouts(layouts) {
    layouts.sort((x, y) => {
      // on trie par largeur puis par longueur
      const measureX = x.name.split("×");
      const measureY = y.name.split("×");
      if (measureX.length !== 2 || measureY.length !== 2) return 0;

      const diff = parseInt(measureX[0], 10) - parseInt(measureY[0], 10);
      if (diff !== 0) return diff;

      return parseInt(measureX[1], 10) - parseInt(measureY[1], 10);
    });
  }

  render() {
    const t = this.context;
    let availablesLayouts = this.props.layouts.filter(
      layout => layout.available
    );
    availablesLayouts = !availablesLayouts || availablesLayouts.length == 0 ? this.props.layouts : availablesLayouts
    this.sortLayouts(availablesLayouts);
    const defaultLayoutSize = replaceMultiplicationSymbol(this.getLayoutSize(this.props.layout,availablesLayouts));
    const monumentComponents = ["SEM","PLT","ACC"];
    const isMonumentComponent = monumentComponents.includes(this.props.monument.category)
    const disabled = availablesLayouts.length <= 0 || isConfigRequested();
    const buttonContent = t("config3d_monument_button_dimensions") || "Recette";
    return (
      <OptionPanel
        closeOnClickInside={true}
        className="LayoutOptionPanel"
        buttonContent={buttonContent}
        buttonIcon="layout-icon"
        disabled={disabled}
        showValue={defaultLayoutSize}
        isMonComponent={isMonumentComponent}
        isLayout = {true}
        // buttonClassName="btn-config-secondary text-dark background-page secondary3-hover text-dark-border"
        buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabled && 'config-button-hover'}`}
      >
        {_.chain(availablesLayouts)
          .map(layout => (
            <button
              key={layout.reference}
              onClick={() => this.onSelectItem(layout)}
              className={cn("OptionPanelSquareItem secondary3-hover", {
                active: this.props.layout === layout.reference
              })}
            >
              {layout.name}
            </button>
          ))
          .value()}
      </OptionPanel>
    );
  }
}

export const LayoutOptionPanel = connect(
  (state, ownProps) => ({
    layout: _.get(state, "configurator.current.configuration.monument.layout"),
    layouts: state.configurator.current.options.layouts,
    filteredLayouts: state.catalog.filters.layouts,
    needFiltering:state.configurator.needFiltering,
    monument:state.configurator.current.configuration.monument,
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    configActions: bindActionCreators(configurationActions, dispatch)
  })
)(LayoutPickerComponent);
