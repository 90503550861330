import React, { Component } from 'react'
import PropTypes from 'prop-types'
import eventBus from '../services/EventBus'
import { Modal } from 'reactstrap'

class ElemConfirm extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
    centered: PropTypes.bool,
    fade: PropTypes.bool,
    eventName: PropTypes.string,
    data: PropTypes.object
  }

  applyConfirm = (response) => {
    eventBus.dispatch(this.props.eventName, {
      response: response,
      value: this.props.data
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className='ModalElemConfirm'
        centered={true}
        fade={true}
      >
        <div className='ElemConfirm'>
          <div className='header modal-header'>
            <h2 className='modal-title'>{this.props.title}</h2>
            <button
              type='button'
              className='close'
              aria-label='Close'
              onClick={() => {
                this.applyConfirm(false)
              }}
            >
              <i className='icon material-icons'></i>
            </button>
          </div>

          <div className='content p-4 py-5 modal-body'>
            {this.props.message}
          </div>

          <div className='p-4 modal-footer'>
            <span
              className='cancel'
              onClick={() => {
                this.applyConfirm(false)
              }}
            >
              Annuler
            </span>

            <span
              className='confirm'
              onClick={() => {
                this.applyConfirm(true)
              }}
            >
              Continuer
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ElemConfirm
