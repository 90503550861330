import React, { Component } from "react";
import { connect } from "react-redux";
import {SprojectSelect} from "./SprojectSelect ";
import {Row,Col} from 'reactstrap';
import {LeftOvalAction} from "../../../components/LeftOvalAction";
import MediaQuery from "react-responsive/src";
import {isSmartphone} from "../../../services/domFunctions";
import { gtmFamily } from "../../../services/gtmFamily";
import { handleProject } from "../utils/utils";
import * as familyspaceActions from '../../../actions/familySpaceActions';
import { bindActionCreators } from "redux";
import loadingPicto from "../../../images/icons-config-famille/loading.svg";
import * as _ from "lodash";




class SselectProjectsComponent extends Component {
  state = {
    searchInput: '',
    selectedProjects: [],
    projectsToAdd: {},
    loadingProjects: false,
    searchOffset: 0,
    projects:this.props.projects,
    hasMoreProjects:true
  }

  async componentDidMount() {

    let projectsToAdd = {}
    let projects=this.props.projects
    projects.forEach((project) => {
      projectsToAdd[project] = true
    })
    this.updateProjectIds()
    this.setState({projects:this.props.familyService.projects,projectsToAdd})

  }
async componentDidUpdate(prevProps,prevState){
  if(prevProps.familyService.isResearch !== this.props.familyService.isResearch){
    this.setState({hasMoreProjects:true})
  }
  
  const prosProjects = _.get(this.props, "familyService.projects", []).filter(p => p.archived == 0);
  const prevProsProjects = _.get(prevProps, "familyService.projects", []).filter(p => p.archived == 0);
  
  if(prevState.searchInput!=="" && this.state.searchInput===""){
    this.clearSearch()
  }
  if (prosProjects.length!==prevProsProjects.length) {
      this.setState({ loadingProjects: true })

      // let projects =  this.props.familyService.projects
      // await handleProject(this.props.familyService.isResearch,projects)
      this.updateProjectIds()
    this.setState({projects:this.props.familyService.projects})

  }
}


  updateProjectIds = () => {
    const projectIds = []
    const projects= this.props.familyService.isResearch ? this.props.familyService.searchedProjects.filter(project=>project.name.toLowerCase()===this.state.searchInput.toLowerCase()) :  this.props.familyService.projects
   projects.map((project) => {
      if (project.archived === this.props.archived) projectIds.push(project.id)
    })
    this.setState({ selectedProjects: projectIds, loadingProjects: false })
  }

  toggle = (id) => {
    let copyAdded = { ...this.state.projectsToAdd }
    let toAdd = this.state.projectsToAdd[id] ? false : true
    let countIds = Object.keys(copyAdded).filter(
      (id) => copyAdded[id] === true
    ).length
    let nbMax = isSmartphone() ? 3 : 4
    if (countIds < nbMax || !toAdd) {
      copyAdded[id] = toAdd
    }
    this.setState({ projectsToAdd: copyAdded })
  }

  addProjects = () => {
    let addedProjects = []
    //TODO mettre les
    for (const id in this.state.projectsToAdd) {
      if (this.state.projectsToAdd[id]) {
        addedProjects.push(id)
      }
    }
    // GTM
    gtmFamily().selectedProjectsCompare(
      this.props.familyService.isResearch 
      ? this.props.familyService.searchedProjects.filter(p=>p.archived===0 && p.name.toLowerCase()===this.state.searchInput.toLowerCase())
      : this.props.familyService.projects.filter((p) => p.archived == 0)
    )
    // end GTM

    this.props.addedProjects(addedProjects)
    !!this.props.familyService.isResearch && this.props.familySpaceAction.updateResearchState(false)

  }
  handleSearchInput = ({ target }) => {

    this.setState({
      searchInput: target.value
    })
  }

  handleScroll = async (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    let clientBottom=scrollHeight-scrollTop-5
    if (Math.floor(clientBottom) <= clientHeight && !this.state.loadingProjects) {
      if (!this.props.familyService.isResearch) {
        this.setState({ loadingProjects: true })
        const {dOffset,specOffset}=this.props.familyService.offsets
        let limit=this.props.familyService.limit
        let projects = await this.props.familySpaceAction.getProjects(`dOffset=${dOffset}&specOffset=${specOffset}&archived=${false}&limit=${limit}`,false)
        !projects.length && this.setState({hasMoreProjects:false})
         projects= await handleProject(this.props.familyService.isResearch,projects)
    this.setState({projects:projects})
        this.updateProjectIds()

      } else {
        this.searchProjects()
      }
    }
  }


  searchProjects=async ()=>{
    this.setState({loadingProjects:true})
    this.props.familySpaceAction.updateResearchState(true)
    this.setState({loading:true})
    let limit=this.props.familyService.limit
    let offset= this.props.familyService.searchedProjects.filter(project=>project.name.toLowerCase()===this.state.searchInput.toLowerCase()).length
    let projectsByName =await this.props.familyspaceActions.getProjectsByName(this.state.searchInput,offset,`archived=${false}&limit=${limit}`)
    !projectsByName.length && this.setState({hasMoreProjects:false})
    let projects=await handleProject(this.props.familyService.isResearch,projectsByName)
     projectsByName=projects.filter(project=>project.name.toLowerCase()===this.state.searchInput.toLowerCase())
    this.updateProjectIds()


  }

  clearSearch=async()=>{
    const projectIds = []
    this.props.familyService.projects.map((project) => {
      if (project.archived === this.props.archived) projectIds.push(project.id)
    })
    this.props.familySpaceAction.updateResearchState(false)

    this.setState({ searchInput:"",selectedProjects: projectIds, loadingProjects: false ,searchOffset:0})
  }

  render() {
    const { searchInput, selectedProjects, projectsToAdd } = this.state
    const { mainColor, fontColor } = this.props.adminConfig.config


    return (
      <div className='SselectProjects'>
        <Row className='SselectHeader'>
          <div className='title'>Sélectionnez les projets à comparer</div>
          <MediaQuery maxWidth={576}>
            <span className='subTitle'>(3 projets au maximum)</span>
          </MediaQuery>
          <MediaQuery minWidth={576}>
            <span className='subTitle'>(4 projets au maximum)</span>
          </MediaQuery>
          <MediaQuery minWidth={576}>
            <div className='actions'>
              <LeftOvalAction
                text='Valider'
                addClass='fsp-button'
                colorConfig={{ mainColor, fontColor }}
                action={() => this.addProjects()}
              />
              <LeftOvalAction
                text='Annuler'
                addClass='fsp-button cancel'
                action={() => {
    !!this.props.familyService.isResearch && this.props.familySpaceAction.updateResearchState(false)
                  this.props.close()}}
              />
            </div>
          </MediaQuery>
        </Row>
        <div className="Ssearch">
                <input placeholder="Chercher un monument" type="text" value={searchInput} onChange={e => this.handleSearchInput(e)}/>
               {this.state.searchInput && <button disabled={this.state.loadingProjects} onClick={this.clearSearch} style={{border:"none"}}> <i className="icon material-icons" >close</i></button>}
               <button disabled={this.state.loadingProjects || this.state.searchInput===""} onClick={this.searchProjects} style={{border:"none"}}><i className="icon material-icons" >search</i></button>
              </div>
              {this.state.loadingProjects && (
            <div
              className='loadingBloc'
              style={{position:'absolute', display: 'flex', justifyContent: 'center',alignItems:'center',width:'100%',height:'100%',zIndex:'100' }}
            >
              <img
                className='loadPicto'
                src={loadingPicto}
                alt='Chargement...'
              />
            </div>
          )}
        <div className='AllProjects' onScroll={(e)=>{this.state.hasMoreProjects && this.handleScroll(e)}}>
          <Row className=''>
            {selectedProjects.length > 0 &&
              selectedProjects.map((projectId, index) => {
                return(
                <Col sm='12' md='6' key={index}>
                  <SprojectSelect
                    key={index}
                    projectId={projectId}
                    toggle={() => this.toggle(projectId)}
                    checked={projectsToAdd[projectId]}
                    isResearch={this.props.familyService.isResearch}
                  />
                </Col>
              )
              })}
          </Row>

        </div>
        <MediaQuery maxWidth={576}>
          <div className='actions'>
            <LeftOvalAction
              text='Annuler'
              addClass='Grey fsp-button cancel'
              action={() => this.props.close()}
            />
            <LeftOvalAction
              text='Valider'
              addClass='fsp-button'
              colorConfig={{ mainColor, fontColor }}
              action={() => this.addProjects()}
            />
          </div>
        </MediaQuery>
      </div>
    )
  }
}

export const SselectProjects = connect(
  (state) => ({
    familyService:state.familyService,
    adminConfig: state.adminConfig
  }), dispatch => ({
    familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
    familyspaceActions: bindActionCreators(familyspaceActions, dispatch),
  })
)(SselectProjectsComponent);

