import queryString from 'query-string';
import {addMessage} from './message.service'
import {checkToken, getReactAppExtranet} from './utils.service';
import {getStore} from '../store';
import oauth from './oauth';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

let jwt;
let _request;
let match = window.location.search.match(/[?&]code=([^&]+)/);
if (match && match[1]) {
  // exchange authorization `code` for an OAuth 2.0 `access_token`:
  oauth.authorizationCodeGrant(match[1]);
  _request = fetchWithOAuth;
} else {
  match = window.location.search.match(/[?&]token=([^&]+)/);

  if (match && match[1]) {
    // use the JWT `token` as-is (deprecated):
    jwt = match[1];
    _request = fetchWithJWT;
  } else {
    // no authentication (e.g. family)
    _request = _fetch;
  }
}

export function createMemberSession(path) {
  const options = {
    method: "POST",
    headers: Object.assign({}, defaultHeaders),
    body: JSON.stringify({ return_to: path }),
  };
  return fetchWithOAuth(getReactAppExtranet("/api/v4/sessions"), options)
    .then(response => response.json())
    .then(response => response.url);
}

export function get(url, queryParams,headers =null,type=false) {
  return req('GET', url, undefined, queryParams,headers,false,type);
}

export function post(url, body, queryParams, _headers = false, fullUrl = false,type=false) {
  return req('POST', url, body, queryParams,null,fullUrl,type);
}

export function put(url, body, queryParams) {
  return req('PUT', url, body, queryParams);
}

export function del(url, queryParams) {
  return req('DELETE', url, queryParams);
}

function req(method, _url, body, queryParams={}, _headers,fullUrl = false,type = false) {
  let url = fullUrl ? _url : process.env.REACT_APP_CONFIG3D_ORIGIN + _url;

  if (getStore().getState().user.family) {
    if (url.indexOf("?")===-1){
      queryParams['client'] = getStore().getState().user.family;
    } else {
      url += `&client=${getStore().getState().user.family}`;
    }
  }

  if (queryParams && Object.entries(queryParams).length !== 0) {
    url += '?' + queryString.stringify(queryParams);
  }

  const options = {
    method,
    headers: Object.assign({}, defaultHeaders, _headers || {}),
    // headers: Object.assign({}, {'Accept': 'application/json', "Content-Type": 'application/json; multipart/form-data; boundary=----WebKitFormBoundaryqzByvokjOTfF9UwDeb'})
    //credentials: 'same-origin', // Obligatoire pour envoyer les cookies d'authentification...
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const promise = _request(url, options);

  if (type === "blob") {
    return promise
      .then(check => check.status <= 300 ? check : null)
      .then(rsp => rsp ? rsp.blob() : null);
  } else {
    return promise
      .then(checkNotAuthorized)
      .then(checkStatus)
      .then(parseJSON);
  }
}

function fetchWithJWT(url, options) {
  return _fetch(url, options, `JWT ${jwt}`)
}

function fetchWithOAuth(url, options, tokens) {
  return oauth.then(tokens => {
    return _fetch(url, options, `Bearer ${tokens.access_token}`).then(response => {
      if (response && response.status === 401) {
        // try to refresh expired token (once):
        return oauth
          .refreshTokenGrant(tokens.refresh_token)
          .then(tokens => _fetch(url, options, `Bearer ${tokens.access_token}`));
      }
      return response;
    });
  });
}


function _fetch(url, options, authorization) {
  if (authorization) {
    options.headers["Authorization"] = authorization;
  }

  return fetch(url, options)
    .then(checkNotAuthorized)
    .catch(() => {
      addMessage({
        id: 'network-error',
        className: 'error',
        innerHTML: 'Impossible de joindre le serveur, merci de réessayer'
      })
      // throw new Error('Impossible de joindre le serveur');
    });
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    return response.text()
      .catch(() => {
        throwError(response);
      })
      .then(responseText => {
        if (isJson(responseText)) {
          return JSON.parse(responseText);
        }
        throwError(response, responseText);
      });
  }
}

function checkNotAuthorized(response) {
  if (parseInt(response.status,10) === 401 ){
    response.json().then(body => {
      if (body.error === 402) {
        window.parent.postMessage('config3d_401', process.env.REACT_APP_EXTRANET);
      }
    });
  }
  return response
}

function throwError(response, body) {
  let message;
  if (body) {
    checkToken(body,true); // En cas d'expiration de token on essaie d'en récupérer un via refresh de la page
    message = `[${response.status}] ${body}`;
  } else {
    message = `[${response.status}] ` + (response.statusText || 'Erreur serveur');
  }
  if (parseInt(response.status,10) === 403 || parseInt(response.status,10) === 401 ){
    message = "Opération non autorisée";
  }
    var error = new Error(message);
    error.response = response;
    throw error

}

function parseJSON(response) {
  if (response.err && !response.status) {
    return response;
  }
  return response.json();
}

function isJson (str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

 function mainExternal(method,partialUrl,body=false, header = defaultHeaders){

  const url = getReactAppExtranet(`/api/v4/${partialUrl}`);
  const headers = Object.assign({}, header);

  if (body instanceof FormData) {
    // remove the default application/json header, otherwise fetch won't set the
    // correct multipart/form-data type with the boundary option (required)!
    delete headers["Content-Type"];
  } else if (body) {
    // send the request as JSON (default)
    body = JSON.stringify(body);
  }
  const params = body ?{ method, body, headers } : { method, headers };

  return fetchWithOAuth(url, params).then(response => { 
    if (response) return response.json();
    return null
  }
  );

  

 }

export  function  putExternal(partialUrl,body){
  return mainExternal("PUT",partialUrl,body);
}
export  function  patchExternal(partialUrl,body){
  return mainExternal("PATCH",partialUrl,body);
}
export  function  postExternal(partialUrl,body){
  return mainExternal("POST",partialUrl,body);
}
export  function  deleteExternal(partialUrl){
  return mainExternal("DELETE",partialUrl);
}
export  function  getExternal(partialUrl){
  return mainExternal("GET",partialUrl);
}

