import * as types from './actionTypes';
import {postFs,getFs,putFs,deleteFs, getFsGcsUrl} from '../services/api.familyspace.service';
import * as api from '../services/api.service';
import * as _ from 'lodash';
import {  isArray } from 'lodash';


export function login (username, password) {
    const formRegister = false
    const client = null
    const device = deviceDetect();
    const browser = browserDetect();
    let date = new Date(Date.now())
    date = date.toISOString().slice(0, 19).replace('T', ' ')
   return dispatch => {
    return api.post(`/api/familyspace/login`, {username, password, formRegister, client, date, device, browser})
    .then(result => {
      if (!result || result.error || result.err ) {
        dispatch({type: types.FS_USER, payload: null});
        dispatch({type: types.FS_SPACE, payload: null});
        dispatch({type: types.FS_PROJECTS, payload: []});
        dispatch({type: types.FS_MEMBERS, payload: []});
        dispatch({type: types.FS_MESSAGES, payload: []});
        return result;
      } else {
        logInManagement(dispatch,result);
        return true;
      }
    });
   };
};

function browserDetect () {
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browserName  = navigator.appName;

  let nameOffset,verOffset,ix;

  // In Opera 15+, the true version is after "OPR/" 
  if ((verOffset=nAgt.indexOf("OPR/"))!=-1) {
  browserName = "Opera";
  }
  // In older Opera, the true version is after "Opera" or after "Version"
  else if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
    browserName = "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "IExplorer";
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
  browserName = "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
            (verOffset=nAgt.lastIndexOf('/')) ) 
  {
    browserName = nAgt.substring(nameOffset,verOffset);
    if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  if(browserName==='Chrome'){
    if (nAgt.indexOf("Edg") != -1){
      browserName="Edge"
    }
  }
  if (browserName==='Netscape'){
    browserName="IExplorer"
  }
  return browserName;
}

function deviceDetect () {
  let nAgt = window.navigator.userAgent;
  let platform = window.navigator.platform
  let deviceType = "Unknown"

  let notDesktop = false
  let mobileDeviceValues = ['iPhone','iPod','iPad','Android','BlackBerry','Linux armv7l','null']
  for (let i=0;i<=mobileDeviceValues.length-1;i++){
    if (platform.includes(mobileDeviceValues[i])){
      notDesktop=true
    }
  }

  if (notDesktop === false){
    deviceType = "Desktop"
  } else {
    deviceType="Mobile"
  }
  return deviceType;
}

function logInManagement(dispatch,result){
  localStorage.setItem('gpg-familyspace-token',result.jwt);
  localStorage.setItem('gpg-familyspace-user', JSON.stringify(result.user));
  localStorage.setItem('gpg-familyspace-members', JSON.stringify(result.members));
  localStorage.setItem('gpg-familyspace', JSON.stringify(result.familySpace));
  dispatch({type: types.FS_USER, payload: result.user});
  dispatch({type: types.FS_SPACE, payload: result.familySpace});
  dispatch({type: types.FS_MEMBERS, payload: result.members});
  dispatch({type: types.FS_MESSAGES, payload: result.messages});
  dispatch({type: types.FS_TOKEN, payload: result.jwt});
}

export function logout () {
  localStorage.removeItem('gpg-familyspace-user');
  localStorage.removeItem('gpg-familyspace-members');
  localStorage.removeItem('gpg-familyspace-files');
  localStorage.removeItem('gpg-familyspace-token');
  localStorage.removeItem('gpg-familyspace');  
  return {type: types.FS_LOGOUT};
};

export function saveEmail (email) {
  return {type: types.FS_EMAIL,payload: email};
};

export function updateEmailSent(payload) {
  return {
    type: types.FS_UPDATE_EMAIL_SENT,
    payload: payload
  };
};

export function getMembers () {
  return (dispatch, getState) => {

    if (!getState().familyService || !getState().familyService.familySpace) {return ([])};// Cas où on a été déconnecté

    const familyspace_id = getState().familyService.familySpace.id;
    return getFs(`/api/familyspace/${familyspace_id}/members`)
    .then(response => {
      if (response) {
        dispatch({type: types.FS_MEMBERS, payload: response});
        localStorage.setItem('gpg-familyspace-members', JSON.stringify(response));
      }
      return response;
    });
  }
};

export function getClientCandidates (clientId) {
  return (dispatch) => {
    return api.get(`/api/familyspace/getclientCandidates/${clientId}`)
    .then(response => {dispatch({type: types.FS_CANDIDATES, payload: response}); return response});
  }
};

export function updateMember (data) {
  return (dispatch, getState) => {
    const member_id = getState().familyService.user.id;
    return putFs(`/api/familyspace/member`, {...{member: member_id}, ...data})
    .then(response => {
      this.getMembers();
      // localStorage.setItem('gpg-familyspace-members', JSON.stringify(response));
      // dispatch({type: types.FS_MEMBERS, payload: response});
      return response;
    });
  };
};

export function removeMember (member_id) {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;

    return deleteFs(`/api/familyspace/member`, {member: member_id, familyspace: familyspace_id})
    .then(response => {
      if (response && isArray(response)) {
        localStorage.setItem('gpg-familyspace-members', JSON.stringify(response));
        dispatch({type: types.FS_MEMBERS, payload: response});
      }
      return response;
    });
  };
};

export function createProjectFromConfig (data) {
  return async (dispatch, getState) =>{

  }
};

export function getProjects (queryParams = false,archived) {
  return async (dispatch, getState) => {
    if (!getState().familyService || !getState().familyService.familySpace) {return ([])};// Cas où on a été déconnecté
    
    const familyspace_id = getState().familyService.familySpace.id;
    const {projects,dProjects,specProjects} = await getFs(`/api/familyspace/${familyspace_id}/projects`, queryParams);

    if (!projects || !Array.isArray(projects)) {
      dispatch({type: types.FS_PROJECTS, payload: []});
      return false;
    } else {
      // initialiser la variable "name" si elle n'existe pas en se basant sur id des variables dans les projets tout en conservant l'ordre d'origine
      const sortedProjects = projects.slice().sort((a, b) => a.id - b.id);
      const _projects = projects.map((project) => {
        const order = sortedProjects.findIndex((sortedProject) => sortedProject.id === project.id);
        if (project.name == "") {
          return { ...project, name: `Projet ${order + 1}`};
        } else {
          return project;
        }
      });
      // dispatch({type: types.FS_PROJECTS, payload: [...getState().familyService.projects ,..._projects]});
      this.getNextProjects({dOffset:dProjects,specOffset:specProjects},archived)
      return projects;
    }
  };
};

export function getNextProjects(offsets,archived){
  return async(dispatch,getState)=>{

    let {dOffset,specOffset}=archived ? getState().familyService.archivedProjectsOffsets : getState().familyService.offsets;
    dOffset+=offsets.dOffset
    specOffset+=offsets.specOffset
    dispatch({type: archived ? types.FS_ARCHIVED_PROJECTS_OFFSETS  :types.FS_PROJECTS_OFFSETS, payload: {dOffset,specOffset}});
  }
}


export function imageConfigProject (isResearch,{projectId, loading , error, errorMsg}) {
  
  if(isResearch) return dispatch => dispatch({type: types.FS_SEARCH_PROJECT_IMAGE_STATUS, payload: {projectId, loading, error, errorMsg}})
  return dispatch => dispatch({type: types.FS_PROJECT_IMAGE_STATUS, payload: {projectId, loading, error, errorMsg}});
};

export function storeImageConfigProject(isResearch,projectId,image,config){
  return async (dispatch) => {
  isResearch ? dispatch({type: types.FS_SEARCHED_PROJECT_IMAGE, payload: {id:projectId,image,config}}) :  dispatch({type: types.FS_PROJECT_IMAGE, payload: {id:projectId,image,config}})};
}

export function getOrgsInfos(projects){
  return async (dispatch) => {
    if (projects && projects.length){
      let projectClients = projects.map(item=> item.client);
       projectClients = projectClients.filter((v, i, a) => a.indexOf(v) === i); //unique values   
      projectClients = projectClients.filter(item=>item) ; //remove empty values
      let orgInfos = await api.post(`/api/organizations/infos`,projectClients);
      dispatch({type: types.FS_ORGANISATION_INFOS, payload:orgInfos});
  }
  return false
  }
}
/**
 *
 * @param {int} projectId
 * @param {object} data data to update (ex: {name: "Nouveau nom"})
 */
export function updateOneProject (projectID, data) {
  return (dispatch, getState) => {

    return putFs(`/api/familyspace/project/${projectID}`, data)
    .then(response => {
      if (response){
        const index =getState().familyService.isResearch ? getState().familyService.searchedProjects.findIndex(elt=>elt.id === projectID): getState().familyService.projects.findIndex(elt=>elt.id === projectID);
        if (index !== -1){
          const newProjects = _.cloneDeep(getState().familyService.isResearch ? getState().familyService.searchedProjects : getState().familyService.projects);
          for (let member in data) { newProjects[index][member]= data[member];}
          
          dispatch({type: getState().familyService.isResearch ? types.FS_UPDATE_SEARCHED_PROJECTS : types.FS_PROJECTS, payload:newProjects});
          return newProjects[index];
        }
      }
      return false
    });
  };
};

/**
 *
 * @param {int} project_id
 */
export function deleteProject (project_id) {
  return (dispatch, getState) => {
    let files = getState().familyService.files;
    _.remove(files, e => Number(e.project_id) === Number(project_id));

    dispatch({type: types.FS_FILES, payload: files});
    if(isArray(files)){
      localStorage.setItem('gpg-familyspace-files', JSON.stringify(files));
    }
    

    return deleteFs(`/api/familyspace/project/${project_id}`)
    .then(response => {

      if (response) {
        let projects = getState().familyService.projects;
        _.remove(projects, p => Number(p.id) === Number(project_id));
        dispatch({type: types.FS_PROJECTS, payload: projects});
      }

      return response;
    });
  };
}

/**
 *
 * @param {object} data
 */
export function postProject (data) {
  return (dispatch, getState) => {
    const userId = getState().familyService.familySpace.member_id;
    data = {...data, user_id: userId};
    return postFs(`/api/familyspace/project`, data)
    .then(response => {
      if (response){
        const payload = [response,...getState().familyService.projects];
        dispatch({type: types.FS_PROJECTS, payload });
        return payload;
      }
      return false

    });
  }
};

export function updateFamilyspace (data) {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;

    return putFs(`/api/familyspace`, {...data,...{id: familyspace_id}})
    .then(response => {
      dispatch({type: types.FS_SPACE, payload: response});
      localStorage.setItem('gpg-familyspace', JSON.stringify(response));
      return response;
    });
  };
};

export function addComment (comment) {
  return dispatch => {
    return postFs(`/api/familyspace/comment/`, comment)
    .then(response => {
      dispatch({type: types.FS_ADD_COMMENT, payload: response});
      return response;
    });
  };
}
export function updateComments (comments){
  return (dispatch,getState) =>{
    const familyspace_id = getState().familyService.familySpace.id;
    const projectID = comments && comments.length>0 && comments[0].project_id;
    return putFs(`/api/familyspace/${familyspace_id}/updateComments`,comments)
      .then(response =>{
        if (response){
          const index = getState().familyService.projects.findIndex(elt=>elt.id === projectID);
          if (index !== -1){
            const newProjects = _.cloneDeep(getState().familyService.projects);
            newProjects[index].comments= comments;
            dispatch({type: types.FS_PROJECTS, payload:newProjects});
            return newProjects[index];
          }
        }
        return false
      })
  }
}

/**
 * Envoie une/plusieurs invitations
 * Si le mail d'invitation est déjà associé à un espace famille alors celui-ci est directement ajouté + notif mail, sinon création de compte + mail d'activation + mot de passe
 * @param {array} data array of string '["myemail@mail.com", "example@mail.com"]'
 */
export function sendInvitations (data, resend = false) {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    const sender = getState().familyService.user.email;
    const senderName = getState().familyService.user.name;
    const file = getState().familyService.files.find(file => file.context === "avatar") || null;
    let imageURL =  null;

    if (file) {
      imageURL = getFsGcsUrl(familyspace_id,`${file.uuid}.${file.ext}`);
    }

    return postFs(`/api/familyspace/${familyspace_id}/invitations`, {data, ...{sender, senderName, imageURL, resend}})
    .then(response => {
      return response;
    });
  };
}
export function shareProject (emails,project){
  return (dispatch, getState) => {
    const projectId = project.id;
    const image = project.configImage;
    const sender = getState().familyService.user.email;
    const senderName = getState().familyService.user.name;
    return postFs(`/api/familyspace/shareProject`, {projectId, emails, sender, senderName, image})
      .then(response => {
        return response;
      });
  }
}


export function setPassword (mail_token, password, confirmPassword) {
  return dispatch => postFs(`/api/familyspace/invitations/password/validate`, {mail_token, password, confirmPassword})
  .then(response => {
    if (response.user) {
      dispatch({type: types.FS_USER, payload: response.user});
      localStorage.setItem('gpg-familyspace-user', JSON.stringify(response.user));
    }
    return response;
  });
};

export function getMessages () {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    return getFs(`/api/familyspace/${familyspace_id}/messages`)
    .then(response => {
      dispatch({type: types.FS_MESSAGES, payload: response});
      return response;
    });
  };
};

export function addMessage (user, message) {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    return postFs(`/api/familyspace/${familyspace_id}/messages`, {user, message})
    .then(response => {
      if(response){
        dispatch({type: types.FS_ADD_MESSAGE, payload: response});
      }
      return response;
    });
  };
};

export function isAuth () {
  return (localStorage.getItem('gpg-familyspace-user') && localStorage.getItem('gpg-familyspace'));
}

export function register (data, fromFamily = true, client = null) {
    const device = deviceDetect();
    const browser = browserDetect();
    let date = new Date(Date.now())
    date = date.toISOString().slice(0, 19).replace('T', ' ')
  return dispatch => {
    const postFunction = fromFamily ? postFs : api.post;
    const url = client ? `/api/familyspace?client=${client}` : `/api/familyspace`;
    return postFunction(url, data, client, "",date,device,browser)
    .then(result => {
      if(result && result.user ){
        if (fromFamily){
          logout();
          //console.log("Dispatch Result: ",result)
          //logInManagement(dispatch,result);
        }
        return true;
      } 
      return result;
    });
  };
};


export function setSocket (socket) {
  return dispatch => {
    return dispatch({type: types.FS_SOCKET, payload: socket});
  };
};


export function markAsRead (elem) {

  if (elem.type === "likes" || elem.type === "dislikes") {
     return dispatch => {

       const data = elem.project[elem.type].find(s => s.id === elem.id);
       const index = elem.project[elem.type].findIndex(s => s.id === elem.id);

       data.read_by = elem.read_by;
       elem.project[elem.type][index] = data;
       this.updateOneProject(elem.project_id, {[elem.type]: elem.project[elem.type]});
     };
  }

  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    const apiURL = (elem.type === "commentActivities" || elem.type === "comment") ? `/api/familyspace/${familyspace_id}/comments` : `/api/familyspace/${familyspace_id}/messages`;

    return putFs(apiURL, elem)
    .then(response => {
      this.getProjects();
      return response;
    });
  };
};

export function updatePot (pot) {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    return putFs(`/api/familyspace/pot`, {url:pot, spaceId:familyspace_id}).then(response => {
      if (response && !response.error && !response.err) {
        dispatch({type: types.FS_UPDATE_POT, payload: pot});
        //On met à jour le local store
        let localStoreFs = JSON.parse(localStorage.getItem('gpg-familyspace'));
        localStoreFs.pot = pot;
        localStorage.setItem('gpg-familyspace', JSON.stringify(localStoreFs));
        }
    });
  };
};

export function setFile (data, fromClient = false) {
  const action = fromClient ? api.post : postFs;
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;

    return action(`/api/familyspace/${familyspace_id}/files`, data)
    .then(response => {
      if (!fromClient && response){
        // setTimeout(() => {
          dispatch({type: types.FS_FILES, payload: response});
          if(isArray(response)){
            localStorage.setItem('gpg-familyspace-files', JSON.stringify(response));
          }
        // }, 3000);
      }
      return response;
    });
  };
};

export function getFiles () {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;

    return getFs(`/api/familyspace/${familyspace_id}/files`)
    .then(response => {
      if (response && ! response.err){
        dispatch({type: types.FS_FILES, payload: response});
        if(isArray(response)){
          localStorage.setItem('gpg-familyspace-files', JSON.stringify(response));
        }
        // localStorage.setItem('gpg-familyspace-files', JSON.stringify(response));
        return response;
      }
      return false;
    });
  };
};

export function deleteFile (fileId) {
  return dispatch => {
    return deleteFs(`/api/familyspace/remove/file/${fileId}`)
    .then(response => {
      if (response) {
        return this.getFiles().then(files=> files)
      }

      return response;
    });
  };
};

export function removeFamilySpace () {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;

    return deleteFs(`/api/familyspace/${familyspace_id}`)
    .then(res => {
      if (res) {
        this.logout();
        // On sort la grosse artillerie on doit sortir brutalement car l'espace n'existe plus
        window.location.reload();
      }
    });
  };
};

export function getFamilySpace () {
  return (dispatch, getState) => {
    const familyspace_id = getState().familyService.familySpace.id;
    return getFs(`/api/familyspace/${familyspace_id}`)
    .then(res => {
      if (res && res.familyspace) {
        dispatch({type: types.FS_SPACE, payload: res.familyspace});
      }

      return res;
    });
  };
};

export function searchFamilyspaceEmail (email) {

  return dispatch => {
    return api.get(`/api/familyspace/search?email=${email}`)
    .then(response => {
      if (response && response.email) {
        return ({emailExists: response.email, name: response.name});
      }
      return ({emailExists: false});
    });
  }
};

export function associate (mail_token, space_id) {
  return dispatch => postFs(`/api/familyspace/associate`, {mail_token, space_id})
  .then(response => {
    return ({data: response});
  });
};

export function openLoginModal () {
  return dispatch => dispatch({type: types.FS_LOGIN_MODAL, payload: true});
};

export function closeLoginModal () {
  return dispatch => dispatch({type: types.FS_LOGIN_MODAL, payload: false});
};

export function updateSelectedProjects (selectedProjects){
  return dispatch => dispatch({type: types.FS_SELECTED_PROJECTS, payload: selectedProjects});
}
export function updateProjects (projects){
  return dispatch => dispatch({type: types.FS_PROJECTS, payload: projects});
}

export function deleteSelectedProject (deleteProject){
    return dispatch => dispatch({type: types.FS_DELETE_SELECTED_PROJECT, payload: deleteProject});
}
export function addSelectedProject (addedProject){
  return dispatch => dispatch({type: types.FS_ADD_SELECTED_PROJECT, payload: addedProject});
}

export function addDocs (isResearch,payload){
  return isResearch ? dispatch => dispatch({type: types.FS_SEARCHED_PROJECTS_ADD_DOCS, payload}): dispatch => dispatch({type: types.FS_ADD_DOCS, payload});
}


export function getProjectsByName (name,offset=0,queryParams = false) { 
  return async (dispatch, getState) => {
    if (!getState().familyService || !getState().familyService.familySpace) {return ([])};// Cas où on a été déconnecté
    const familyspace_id = getState().familyService.familySpace.id;
    let projects =[] 
    
    if(name!==null) projects=await getFs(`/api/familyspace/${familyspace_id}/${name}/${offset}/projects`, queryParams);

    if (!projects || !Array.isArray(projects)) {
      // dispatch({type: types.FS_UPDATE_SEARCHED_PROJECTS, payload: [...getState().familyService.searchedProjects]});
      return false;
    }
    return projects;
  };
};

export function updateResearchState(isResearch){
  return async (dispatch) => {
    dispatch({type:types.RESEARCH_STATE,payload:isResearch})
  }
}

