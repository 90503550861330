import * as actionTypes from '../actions/actionTypes';
import * as _ from 'lodash';
import {filterLayouts,monumentCategory} from '../constants';
import * as apiService from "../services/api.service";
import {getMonumentImageByGranit} from '../services/image.fct.service';

const initialState = {
  monuments: [],
  monumentsAccessoriesNames: [],
  visibleAccessories: [],
  visibleMonuments: [],
  setPrice: false, // is true when price are added to monuments(visibleMonuments) => final step of loading monuments
  search: null,
  selectedIndex: null,
  selectedAccessory : null, // L'accessoire sélectionné en cas d'utilisation d'accessoire sans monument
  selectedAccessoryGranite: null, // Le granite de l'accessoire en cas d'utilisation d'accessoire sans monument
  error: null,
  loading: false,
  filters:{layouts:["all"]},
  typeFilter:null,
  monumentCategory:monumentCategory,
  catalogLoaded: false,
};
const addPrice = (monuments,visibleMonuments)=>{
  // return monuments
  visibleMonuments.forEach(monument=>{
   let found = monuments.find(elt=>elt.reference === monument.reference);
   monument.price = found && found.price ? found.price : 0;
})
return visibleMonuments;
}
const filterMonuments= (monument,filters)=>{
    // les cinéraires et les doubles ne sont pas concernés par le filtrage
    if (filters.layouts && !filters.layouts.includes("all")  && monument.category !== '900-999' && monument.category !== '700-799') {
      let found= false;
      filters.layouts.forEach(layout=>{ //look for each Layout
        if (layout !== "other"){
          const layoutDef = filterLayouts.find(item=>item.size === layout );
          if(layoutDef){
              found = found || monument.layouts.some(mLayout=>mLayout.layout === layoutDef.name);
          }
        }
        else{
          found = found || monument.layouts.some(mLayout=>!filterLayouts.find(item=>item.name === mLayout.layout) );
        }

      });
      return (found);
    }
    return true;
  }
  const getSimilarMonuments = (monument,theMonument)=>{
    if (theMonument.similar && (( theMonument.similar.includes(monument.monumentId)) || (monument.monumentId === theMonument.monumentId))){
      // On ne retourne que les monuments similaires
      return true
    }
    return false;
  }
  const configuredMonuments = (monuments,adminConfig)=>{
    let newMonuments = monuments.filter(monument=>{
      if (adminConfig && adminConfig.catalogListMonuments
        && adminConfig.catalogListMonuments.length !== 0){
        if (!adminConfig.catalogListMonuments.some( configMnt=> configMnt.reference === monument.reference)){
          return false;
        }
        let adminMonument = adminConfig.catalogListMonuments.find(element =>(element.reference === monument.reference && element.name === monument.name));
        monument.weight = (adminMonument && adminMonument.weight) || 0;
      }
      return true;
    });
    return newMonuments.sort((a,b) => a.weight-b.weight);
  }
  const getNames = (items, oldItems)=>{
    // Available Granites Names to filter
    let names = items.filter(item=> item.name && !(oldItems.some(oldItem=> oldItem.id == item.reference) ) );
    // treat data
    names = names.map((item, index)  => ({ 
        id: item.reference, 
        name:(item.kind? (item.kind.toUpperCase() == "DIVERS"? "ACCESSOIRE": item.kind.toUpperCase()) +' '+ item.name.toUpperCase(): item.name.toUpperCase() ), 
        name2:(item.kind? (cleanSentence((item.kind.toUpperCase() == "DIVERS"? "ACCESSOIRE": item.kind) +' '+ item.name)[0]): (cleanSentence(item.name)[0]) ),
        name3:(item.kind? (cleanSentence((item.kind.toUpperCase() == "DIVERS"? "ACCESSOIRE": item.kind) +' '+ item.name)[1]): (cleanSentence(item.name)[1]) ),
        realName: item.name.toUpperCase() 
    }));
    // concat data (monument and accesoires)
    names = names.concat(oldItems);
    // sort data
    names.sort(function(a, b){
      if(a.realName < b.realName) { return -1; }
      if(a.realName > b.realName) { return 1; }
      return 0;
    });
    
    return names;
  }
  const cleanSentence = (sentence) => {
    return [sentence.toUpperCase().replace(/É|Ê|È|Ë/g, "E"), sentence.toUpperCase().replace(/É|Ê|È|Ë/g, "E").replace(/\s/g, '')]
  }

export default function (state = initialState, {type, payload}) {
  switch (type) {
    // Recherche
    case actionTypes.CATALOG_LOAD_PRICE_START:
    case actionTypes.CATALOG_LOAD_START:
        return Object.assign({}, state, {
          loading: true
      });


    case actionTypes.CATALOG_LOAD_PRICE_SUCCESS:
    case actionTypes.CATALOG_LOAD_SUCCESS:
      return Object.assign({}, state, {
        catalogLoaded: true,
        monuments: configuredMonuments(payload.monuments,payload.adminConfig),
        // SI uniquement les prix ont changé on ajute les prix sans changer l'order
        // SINON Le catalogue affiche la liste par défaut pour que la première recherche soit plus rapide.
        visibleMonuments:payload.price ? addPrice(payload.monuments,state.visibleMonuments):configuredMonuments(payload.monuments,payload.adminConfig),
        exclusive:payload.monuments.some(item=>item.isExclusive === true),
        loading: false,
        monumentsAccessoriesNames: getNames(payload.monuments, state.monumentsAccessoriesNames),
        setPrice: state.setPrice == false ? payload.price : false
      });
    case actionTypes.CATALOG_LOAD_PRICE_FAILURE:
    case actionTypes.CATALOG_LOAD_FAILURE:
      return Object.assign({}, state, {
        error: payload.error,
        loading: false
      });

    case actionTypes.CATALOG_SEARCH:
      //query      category
      return Object.assign({}, state, {
        search: payload.search,
        loading: false,
        visibleMonuments: _.sortBy(_.filter(state.monuments,
          function (monument) {
            if (payload.adminConfig  && payload.adminConfig.catalogListMonuments
                && payload.adminConfig.catalogListMonuments.length !== 0){
              if (!payload.adminConfig.catalogListMonuments.some( configMnt => configMnt.reference === monument.reference)){
                return false;
              }
            }
            let searchAnswer = true;
            if (!payload.search){
              return (filterMonuments(monument,state.filters))
            }
            if (payload.search.category) {
              // || (payload.search.category === 'CIN' && monument.category === '900-999')
              searchAnswer = monument.keywords.includes(payload.search.category); // Cas particulier ou CIN et 900-999 cohabitent
            }
            if (payload.search.query) { // on cherche dans le nom et la référence
              searchAnswer= JSON.stringify(monument.name.toUpperCase()).includes(JSON.stringify(payload.search.query).toUpperCase());
              if (!searchAnswer){
                searchAnswer= JSON.stringify(monument.reference.toUpperCase()).includes(payload.search.query.toUpperCase());
              }
              if (!searchAnswer){ 
                searchAnswer= JSON.stringify(cleanSentence(monument.name)[0]).includes(payload.search.query.toUpperCase());
              }
              if (!searchAnswer){ 
                searchAnswer= JSON.stringify(cleanSentence(monument.name)[1]).includes(payload.search.query.toUpperCase());
              }
            }
            if (searchAnswer && payload.search.layout) { // on cherche dans les layouts
              let found= false;
              const layoutDef = filterLayouts.find(item => item.size === payload.search.layout);
              if(layoutDef){
                  found = monument.layouts.some(mLayout=>mLayout.layout === layoutDef.name);
              }
              searchAnswer = found;
            }
            if (payload.search.isNew) {
              searchAnswer = monument.isNew;
            }
            if (payload.search.isFeatured) {
              searchAnswer =  monument.isFeatured;
            }
            if (payload.search.favorites) {
              searchAnswer = payload.search.favorites.indexOf(monument.reference) !== -1;
            }
            if (payload.search.exclusive) {
              //waiting for exclusive field
              searchAnswer = monument.isExclusive;
            }
            if(searchAnswer && payload.search.searchField) {
              searchAnswer = monument.reference.replace(/\s/g, '').includes(payload.search.searchField.replace(/\s/g, '')) || monument.name.replace(/\s/g, '').includes(payload.search.searchField.replace(/\s/g, ''))
            }
            if (searchAnswer){
              return (filterMonuments(monument,state.filters))
            }
          }), ['monumentOrder'],['asc'])
      });
      case actionTypes.CATALOG_MONU_SEARCH:
        return Object.assign({}, state, {
          search: payload,
          visibleMonuments: payload ?
              state.monuments.filter(monument =>monument.reference.replace(/\s/g, '').includes(payload.replace(/\s/g, '')) || monument.name.replace(/\s/g, '').includes(payload.replace(/\s/g, '')))
            :
              state.monuments
        });
      case actionTypes.CATALOG_SEARCH_ACCESSORIES:
        return Object.assign({}, state, {
          visibleAccessories: payload && payload.search !== undefined && payload.search !== "" ? 
              (payload.accessories.filter(accessory=> {
                // return accessory.reference.includes(payload.search) || accessory.name.includes(payload.search)
                let _kind = accessory.kind.toUpperCase() == "DIVERS"? "ACCESSOIRE": accessory.kind.toUpperCase();
                return accessory.reference.includes(payload.search) || accessory.name.toUpperCase().includes(payload.search.toUpperCase()) || (cleanSentence(_kind +' '+ accessory.name)[0]).includes(payload.search.toUpperCase()) || (cleanSentence(_kind +' '+ accessory.name)[1]).includes(payload.search.toUpperCase());
              })) 
            : 
              payload.accessories,
          monumentsAccessoriesNames: getNames(payload.accessories, state.monumentsAccessoriesNames)
        });
      case actionTypes.CATALOG_FILTER:
        return Object.assign({}, state, {
          filters: payload,
          visibleMonuments: payload ? _.filter(state.monuments,
            monument=>(filterMonuments(monument,payload))
            ) : state.monuments
        });
      case actionTypes.CATALOG_ORDER_SIMILAR:
        return Object.assign({}, state, {
          visibleMonuments: payload ? _.filter(state.monuments,
            monument=>(getSimilarMonuments(monument,payload))
            ) : state.monuments
        });
      case actionTypes.CATALOG_FILTER_TYPE:
            return Object.assign({}, state, {
              typeFilter:payload,
              visibleMonuments: payload ?
              state.monuments.filter(monument=>monument.monumentType === payload)
              : state.monuments
            });
    // Sélection du monument
    case actionTypes.CATALOG_SELECT_MONUMENT:
      return Object.assign({}, state, {
        selectedIndex: _.findIndex(
          state.visibleMonuments,
          ({reference}) => reference === payload.monument.reference
        )
      });
    case actionTypes.CATALOG_UNSELECT_MONUMENT:
      return Object.assign({}, state, {
        selectedIndex: null
      });
    // Ordering catalog monument
    case actionTypes.CATALOG_CHANGE_ORDER_MONUMENT:
      const mInd = _.findIndex(state.visibleMonuments, { "reference": payload.reference })
      if (mInd != -1) {
        let newVisibleMonuments = [...state.visibleMonuments]
        const isOrderExist = _.findIndex(newVisibleMonuments, { "monumentOrder": parseInt(payload.order) })
        if (isOrderExist != -1) {
          if (newVisibleMonuments[mInd].monumentOrder != "" && newVisibleMonuments[mInd].monumentOrder != undefined) {
            if (parseInt(payload.order) > newVisibleMonuments[mInd].monumentOrder) {
              newVisibleMonuments.map(obj => {
                if (obj.monumentOrder >= newVisibleMonuments[mInd].monumentOrder+1 && obj.monumentOrder <= parseInt(payload.order))
                  obj.monumentOrder--;
              })
            }
            else if(parseInt(payload.order) < newVisibleMonuments[mInd].monumentOrder) {
              newVisibleMonuments.map(obj => {
                if (obj.monumentOrder >= parseInt(payload.order) && obj.monumentOrder <= newVisibleMonuments[mInd].monumentOrder-1)
                  obj.monumentOrder++;
              })
            }
          }
          else {
            newVisibleMonuments.map(obj => {
              if (obj.monumentOrder >= parseInt(payload.order)) obj.monumentOrder++;
            })
          }
        }
        const mNewInd = _.findIndex(newVisibleMonuments, { "reference": payload.reference })
        newVisibleMonuments[mNewInd].monumentOrder = parseInt(payload.order)
        // clear monumentOrder where order == ""
        newVisibleMonuments.map(obj => {
          if (obj.monumentOrder === "") obj.monumentOrder = undefined
        })
        // SORT MONUMENTS ARRAY
        newVisibleMonuments = _.sortBy(newVisibleMonuments, ['monumentOrder'],['asc']);
        // clear order input
        if (newVisibleMonuments[mInd].monumentOrder == undefined)
          newVisibleMonuments[mInd].monumentOrder = ""
        // state
        return Object.assign({}, state, {
          visibleMonuments : newVisibleMonuments
        });
      }
    case actionTypes.CATALOG_RESET_ALPHABETICAL_ORDER:
      let newVisibleMonuments = [...state.visibleMonuments]
      // SORT MONUMENTS
      newVisibleMonuments = _.sortBy(newVisibleMonuments, ['name'],['asc']);
      newVisibleMonuments.map((obj,index) => obj.monumentOrder = index+1 )
      // state
      return Object.assign({}, state, {
        visibleMonuments : newVisibleMonuments
      });
    default:
      return state;
  }
}
