import React, { Component } from "react";import { confirmAlert } from 'react-confirm-alert';
import { connect } from "react-redux";
import {FpriceList} from "../family/components/FpriceList";
import {getDisplayName, getDisplayPrice} from "./utils/display";
import {SchangeName} from "./utils/SchangeName";
import {CloseModal} from './utils/display';
import { Folder, Delete, Close } from '@material-ui/icons';
import BackArrow from '../../images/space/arrow-left.png';
import info from '../../images/space/info-circle-fill.svg';
import {SprojectHeaderActions} from './SprojectHeaderActions';
import * as familyspaceActions from '../../actions/familySpaceActions';
import { bindActionCreators } from "redux";
// import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'reactstrap';
import {isFamilyConfigProject,getPriceInfos}  from "./utils/utils";
import {SaskQuote}  from "./Project/SaskQuote";
import history from "../../history";
import MediaQuery from "react-responsive";
import ReactDOM from "react-dom";
import * as _ from 'lodash';
import { gtmFamily } from "../../services/gtmFamily";

export class SprojectHeaderComponent extends Component {
  state = {
    displayPriceInfo: false,changeName: false,variante:false, askQuote:false
  };
  doProjectAction = async (project, action) => {
    const {mainColor, fontColor} = this.props.adminConfig.config
    let arrayActions = {};
    arrayActions['remove'] = {
      title: "Souhaitez-vous supprimer ce projet de votre espace famille ?",
      message: null,
      validate: "Supprimer",
      cancel: "Annuler",
      action: async () => {
        await this.props.familySpaceAction.deleteProject(project.id);
        // GTM
        gtmFamily().ctaOfConfiguration({
          name: "supprimer",
          category: "espace famille",
          subcategory: "supprimer projet",
          type: "lien",
          from: "vignette projet"
        }, { template: "espace famille", subtemplate: "liste projets" }, {}, "partner", "product", "user", "page")
        // end GTM
        this.props.archiveFilter(false);
      }
    };
    arrayActions['unarchive'] = {
      title: "Souhaitez-vous désarchiver ce projet ?",
      message: "Le projet sera déplacé vers <span>Nos projets</span>",
      validate: "Désarchiver",
      cancel: "Annuler",
      action: async () => {
        await this.props.familySpaceAction.updateOneProject(project.id,
           {archived: 0,tstamp:Math.floor(new Date().getTime()/1000)});
            window.location.reload()

        // GTM
        gtmFamily().ctaOfConfiguration({
          name: "désarchiver",
          category: "espace famille",
          subcategory: "liste projets",
          type: "lien",
          from: "vignette projet"
        }, { template: "espace famille", subtemplate: "liste projets" }, {}, "partner", "product", "user", "page")
        // end GTM
        this.props.archiveFilter(true);
      }
    };
    confirmAlert({
      customUI: ({onClose}) =>
        <div className="confirm-alert-arhive-modal">
          <div className="close-action">
            <Close onClick={() => onClose()} />
          </div>
          <h2>{arrayActions[action].title}</h2>
          {arrayActions[action].message && <div className="message" dangerouslySetInnerHTML={{ __html: arrayActions[action].message}} />}
          <div className='actions'>
            <Button className="fsp-button validate" style={{"--f-main-color":mainColor, "--f-font-color":fontColor}} onClick={() => {arrayActions[action].action(); onClose();}}><span>{arrayActions[action].validate}</span></Button>
            <Button className="fsp-button cancel cancel" onClick={() => onClose()}>{arrayActions[action].cancel}</Button>
          </div>
        </div>
    });
  };
  creerVariante = ()=>{
    this.setState({changeName:true,variante:true});
  }
  addArchivePath = () => {
    let path = window.location.href
    return path.includes('archive') ? '/archive' : ''
  };

  gtmProductObj = (project)=> {
    const monument = _.get(project, "config.configuration.monument", {});
    const granites = _.get(project, "config.options.granites", []);
    const graniteMain = granites.find((granit => granit.reference == monument.graniteMain ))
    const graniteSecondary = granites.find((granit => granit.reference == monument.graniteSecondary ))
    
    return {
      id: monument.productId || "",
      name: "", // empty
      reference: monument.reference || "",
      price_from: 0, // empty
      granits: [
        graniteMain ? graniteMain.name : monument.graniteMain, 
        graniteSecondary ? graniteSecondary.name : monument.graniteSecondary
      ],
      styles: [], // second filter <--
      couleurs: [], // empty
      religions: [], // empty
      types: [], // main filter <--
      granit_main: graniteMain ? graniteMain.name : monument.graniteMain,
      flower_button: "", // empty
      v360_button: true //empty
    }
  }

  goToCompare = (project)=>{
    if (project){
      // GTM
      const product = this.gtmProductObj(project);
      gtmFamily().ctaOfConfiguration({
        name: "comparer",
        category: "espace famille",
        subcategory: "comparateur",
        type: "picto",
        from: "vignette projet"
      },
      { 
        template: "espace famille", 
        subtemplate: "liste projets"
      },
      {
        product: product
      })
      // end GTM
      this.props.familySpaceAction.addSelectedProject(project.id);
      history.push(`/famille/${this.props.clientId}/familyspace/projects/compare${this.addArchivePath()}`);
    }
  }
  getProject () {
    const { projectId, familyService } = this.props;
    return (familyService.projects.find(item => item.id === projectId) || familyService.searchedProjects.find(item => item.id === projectId));
  };

  askQuoteAction = (project) => {
    // GTM
    const product = this.gtmProductObj(project);
    gtmFamily().ctaOfConfiguration({
      name: "demander un devis",
      category: "espace famille",
      subcategory: "popup demander un devis",
      type: "picto",
      from: "vignette projet"
    }, 
    { template: "espace famille", subtemplate: "projet" },
    { product: product },
    "partner", "user", "page")
    // end GTM
    this.setState({displayPriceInfo:false,askQuote:true});
  }

  goBacktoProjects = (project) => {
    // GTM
    const { projectId, full } = this.props;
    const product = this.gtmProductObj(project);
    gtmFamily().ctaOfConfiguration({
      name: "retour",
      category: "espace famille",
      subcategory: "liste projets",
      type: "picto",
      from: "vignette projet"
    }, 
    { template: "espace famille", subtemplate: "projet" },
    { product: product },
    "partner", "user", "page")
    // end GTM
    window.history.back();
  }

  render() {
    const {  full, user, familyService, projectIndex} = this.props;
    const {displayPriceInfo, changeName,variante,askQuote} = this.state;
    const theClass = full ? "full Name":"Name";
    const project = this.getProject();
    const price = project && project.price ? project.price : "";
    const priceInfos = getPriceInfos(project,familyService);
    const index = projectIndex+1
    const {mainColor, fontColor} = this.props.adminConfig.config
    const svgPriceInfo = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><style>.a{fill:${mainColor && mainColor};fill-rule:evenodd;}</style></defs><path class="a" d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20ZM11.163,8.235,8.3,8.594l-.1.475.563.1c.368.088.44.22.36.586L8.2,14.094c-.242,1.121.131,1.649,1.01,1.649A2.589,2.589,0,0,0,11.039,15l.11-.52a1.385,1.385,0,0,1-.857.307c-.344,0-.469-.241-.38-.666l1.251-5.881ZM10,6.875a1.25,1.25,0,1,0-1.25-1.25A1.25,1.25,0,0,0,10,6.875Z"/></svg>`
    const backArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.595" viewBox="0 0 16 15.595"><defs><style>.svg-backArrow{fill:${mainColor && mainColor}}</style></defs><path class="svg-backArrow" d="M9.147,52.2l-.793.793a.854.854,0,0,1-1.211,0L.2,46.054a.854.854,0,0,1,0-1.211L7.144,37.9a.854.854,0,0,1,1.211,0l.793.793a.858.858,0,0,1-.014,1.225l-4.3,4.1H15.093a.855.855,0,0,1,.857.857v1.143a.855.855,0,0,1-.857.857H4.83l4.3,4.1A.852.852,0,0,1,9.147,52.2Z" transform="translate(0.05 -37.65)"/></svg>`
    return (
      <div className="SprojectHeader">

        {/* {!!full && <ArrowBack className="arrowback" fontSize='large' style={{fontSize: '21pt'}} onClick={() => window.history.back()} />} */}
        {/* <div className="BackToProjects" onClick={() => this.goBacktoProjects(project)} >
          {!! full && <img className="BackArrow" src={BackArrow} alt="Retour" style={{height:"21px",cursor:"pointer"}} />}
          {!! full && <MediaQuery maxWidth={767}>
            <span>Retour aux Projets</span>
          </MediaQuery>}
        </div> */}

        <div className="headerUp">
          {!! full && <span dangerouslySetInnerHTML={{ __html: backArrow }} className="BackArrow" onClick={() => this.goBacktoProjects(project)} src={BackArrow} alt="Retour" style={{height:"21px",cursor:"pointer"}} />}
          <div className={theClass} onClick={()=>this.setState({changeName:true,variante:false})}>
            {getDisplayName(project, index)}
          </div>
          {/* {!full && !project.archived && <SprojectHeaderActions  project= {project} {...this.props} creerVariante={()=>this.creerVariante()} />}
          { full && !project.archived && <MediaQuery maxWidth={767}>
             <SprojectHeaderActions  project= {project} {...this.props} creerVariante={()=>this.creerVariante()} />
          </MediaQuery>} */}
          {(!full && !!project.archived) &&
            <div className="Archive-actions">
              <div className="fsp-link unarchive-action" onClick={() => this.doProjectAction(project, 'unarchive')}>
                <Folder />
                Désarchiver
              </div>
              <div className="fsp-link remove-action" onClick={() => this.doProjectAction(project, 'remove')}>
                <Delete />
                Supprimer
              </div>
            </div>
          }
        </div>

        {/* <hr/> */}

        <div className="headerDown">
          <div className={`ProjectPrice ${!full && !project.archived ? "PriceToTheLine" : "" } ${!full && project.archived ? "ProjectArchivedPrice" : "" }`}>
            {getDisplayPrice(project)}
            { project && project.config && !!project.show_quote && 
              <span dangerouslySetInnerHTML={{ __html: svgPriceInfo }} onClick={()=>this.displayPriceList(displayPriceInfo)} />
              // <img src={info} onClick={()=>this.displayPriceList(displayPriceInfo)} alt="Information sur les prix"/>
            }
          </div>
          {displayPriceInfo &&
          <div className="FbigDetailB fsp md-whiteframe-8dp">
            <FpriceList current={project && project.config} user={user} notFamily= {!isFamilyConfigProject(project)} priceInfos={priceInfos}
                        fromSpace={isFamilyConfigProject(project)} 
                        askQuote={()=>this.askQuoteAction(project)} 
                        colorConfig={{mainColor, fontColor}}
                        />
            <CloseModal action={()=>this.setState({displayPriceInfo:false})} />
          </div>
          }
          {/* { full && !project.archived && <MediaQuery minWidth={768}>
            <SprojectHeaderActions  project= {project} {...this.props} creerVariante={()=>this.creerVariante()} />
          </MediaQuery>} */}
          {changeName && <SchangeName colorConfig={{mainColor, fontColor}} close={()=>this.setState({changeName:false})} project= {project} variante={variante}/> }
          {askQuote && <SaskQuote 
                        close={()=>this.setState({askQuote:false})} 
                        project={project} 
                        price={price} 
                        config={project && project.config} 
                        colorConfig={{mainColor, fontColor}}
                        user={user}
                        informSent= {()=>this.setState({sent:true})}
                        />}
        </div>
        {/* {!full && !project.archived && <div className="ProjectCompare" onClick={()=>this.goToCompare(project)}> Comparer</div>} */}
      </div>
    
    );
  }
  handleClickOutside = (event)=>{
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        displayPriceInfo: false
      });
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }
  displayPriceList(displayPriceInfo){
    if(!displayPriceInfo){
      // GTM
      const { projectId, full } = this.props;
      gtmFamily().ctaOfConfiguration({
        name: "information prix",
        category: "espace famille",
        subcategory: "popup info prix",
        type: "picto",
        from: "vignette projet"
      }, { template: "espace famille", subtemplate: full ? "projet" : "liste projets" })
      // end GTM
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    this.setState({displayPriceInfo:true})
  }
};
export const SprojectHeader = connect(state => ({
  familyService: state.familyService,
  user: state.user,
  adminConfig: state.adminConfig
}), dispatch => ({
  familySpaceAction: bindActionCreators(familyspaceActions, dispatch),
}))(SprojectHeaderComponent);
