
import {logout} from '../actions/familySpaceActions';
import {getStore} from '../store';
import queryString from 'query-string';
import * as types from '../actions/actionTypes';

export  function  putFs(partialUrl, body) {
  return mainFs("PUT", partialUrl, body);
}
export  function  postFs(partialUrl,body) {
  return mainFs("POST", partialUrl, body);
}
export  function  deleteFs(partialUrl, body) {
  return mainFs("DELETE", partialUrl, body);
}
export  function  getFs(partialUrl, query){
  return mainFs("GET", partialUrl, false, query);
}

 async function mainFs(method,url,body=false, query=false) {
  const token = localStorage.getItem('gpg-familyspace-token');
  let fullUrl;
  // const token = btoa("1234");
  let options = {method,headers:
    Object.assign({},{
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Auth': `${token}`
    })};

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (query) {
    // fullUrl = process.env.REACT_APP_CONFIG3D_ORIGIN + url + `?client=${getStore().getState().user.family}&${queryString.stringify(query)}`  ;
    fullUrl = process.env.REACT_APP_CONFIG3D_ORIGIN + url + `?client=${getStore().getState().user.family}&${query}`;
  } else {
    fullUrl = process.env.REACT_APP_CONFIG3D_ORIGIN + url + `?client=${getStore().getState().user.family}`;
  }

  let answer =  await fetch(fullUrl, options);

  if (answer && answer.ok && answer.status === 200){
    return answer.json();
  }

  if (answer && answer.status === 401) { // le jeton n'est plus valable
    logout();
    getStore().dispatch({type: types.FS_LOGOUT});
}
  else {
    try {
      return answer.json();
    } catch (e) {
      return null;
    }
  }
 }

 export function getFsGcsUrl(spaceId,fileName){
   return  `${process.env.REACT_APP_GCS}/${spaceId}/${fileName}`
 }
