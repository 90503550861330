import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FooterWithRouter, DefaultFooterTitle} from './components/Footer';
import {LayoutOptionPanel} from './components/LayoutOptionPanel';
import {BaseHeightOptionPanel} from './components/BaseHeightOptionPanel';
import {FrameOptionPanel} from './components/FrameOptionPanel';
import {VeneerOptionPanel} from './components/VeneerOptionPanel';
import {HeadstonePicker} from './monument/HeadstonePicker';
import {AccessoryPicker} from './accessory/AccessoryPicker';
import {View3D} from './components/View3D';
import {Flowers} from './components/Flowers';
import {Documents} from './components/Documents';
import {Details} from './completion/Details';
import {UndoRedo} from "./components/UndoRedo";
import {ConfigurationIsoView} from './components/ConfigurationIsoView';
import * as uiActions from '../../actions/uiActions';
import * as tutorialActions from '../../actions/tutorialActions';
import {configurationOrdered, getMonumentCategory} from '../../services/utils.service';
import {isFromStock} from '../../services/restrictedConfiguration';
import {getStore} from '../../store';
import { TranslationContext } from "../../context/TranslationContext";
import * as updateConfigurationActions from '../../actions/updateConfigurationActions' ;
import * as apiService from "../../services/api.service";
import * as configurationActions from "../../actions/configurationActions";
import {AccessoryGranite} from "./granite/AccessoryGranitePicker";
import {AccessoriesGranitePicker} from "./granite/AccessoriesGranitePicker";
import {Steps} from "intro.js-react";
import {introIsAvailable} from "../helpers/introHelpers"
import { isSmartphone } from '../../services/domFunctions';

class ConfiguratorMonumentComponent extends Component {
  constructor(props) {
    super(props);
    this.stepsRef = React.createRef();
    this.skipTutorialMonument = this.skipTutorialMonument.bind(this);
    window["skipTutorialMonument"] = this.skipTutorialMonument;
  }

  static contextType = TranslationContext;
  state = {
    viewTutorial: false,
    stepsEnabled: true,
    initialStep: 0,
    steps: [],
    flagCloseTutorial: false
  };

  async componentDidMount (){
    if (!this.props.configurator.accessories && !this.props.configurator.loadAccessories){
      const accessories = await apiService.get('/api/catalog/accessories');
      this.props.configActions.storeAccessories(accessories);
    }
    this.createInitialAccessory();
    // if(this.props.configuration.monument.category in getMonumentCategory()){
    //   this.props.actions.updateFrame(this.props.configuration)
    // }
    // this.launchIntro(true);
  }

  onBeforeExit = (stepIndex) => {
    const nbSteps = this.state.steps.length - 1
    if (nbSteps !== -1 && this.state.flagCloseTutorial === false) {
      if (stepIndex !== nbSteps) {
        const stepsRef = this.stepsRef.current.introJs
        stepsRef.nextStep();
        return false;
      }
    }
  };

  onExit = () => {
    this.setState({ steps: [] });
  };

  toggleStepsInModal = (modal) => {
    this.setState({flagCloseTutorial: false});
    if (modal) { this.launchIntro(false, modal); }
  };

  toggleSteps = () => {
    this.setState({flagCloseTutorial: false});
    const { steps } = this.state;
    if (steps.length < 1) {
      this.launchIntro(false);
    } else {
      this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
    }
  };

  launchIntro = (loadEvent=false, modal=false) => {
    this.setState({flagCloseTutorial: false});
    const t = this.context;
    const { configIntro } = this.props.configurator;

    const intro = loadEvent
      ? modal ? configIntro.find(element => element.step === "monument" && element.is_modal && element.modal == modal && element.views < 1) : configIntro.find(element => element.step === "monument" && !element.is_modal && element.views < 1)
      : modal ? configIntro.find(element => element.step === "monument" && element.is_modal && element.modal == modal) : configIntro.find(element => element.step === "monument" && !element.is_modal)

    if (intro) {
      const data = intro.data.map(dataItem => {
        const lastSegment = dataItem.intro.split(".").pop();
        dataItem.intro = t(lastSegment);
        return dataItem;
      }).sort((a, b) => {
        return a.order - b.order;
      });
      
      const familyUser = getStore().getState().user
      const member_id = familyUser && familyUser.id ? familyUser.id : null
      // if (loadEvent && member_id) { this.props.configActions.addReadOnboarding(member_id, intro.id); }
      if (member_id) { this.props.configActions.addReadOnboarding(member_id, intro.id); }
      this.setState({ steps: data });
    }
  };

  componentWillMount() {
    

  }
  createInitialAccessory = () => {
    const accessory = this.props.catalog.selectedAccessory;
    const granite = this.props.catalog.selectedAccessoryGranite;
    if (accessory !== null && granite !== null && this.props.configurator.current.configuration.accessories.length<=0 ){
      this.props.actions.addAccessory(accessory, granite);
    }
  }

  openTutorial = () => {
    this.setState({viewTutorial: true});
  };

  closeTutorial = () => {
    this.setState({viewTutorial: false});
  };

  skipTutorialMonument = () => {
    this.setState({flagCloseTutorial: true});
  };

  render() {
    const t = this.context;
    const {configuration, configurator, granites } = this.props;
    const { stepsEnabled, initialStep, steps } = this.state;
    
    const options = {
      tooltipClass: "customTooltip",
      showButtons: false,
      exitOnOverlayClick: true,
      showBullets: true,
      keyboardNavigation: false,
      skipLabel: `
        <a href="#" class="close-intro" onclick='window[\"skipTutorialMonument\"]()'>
          <span>Quitter le didacticiel</span>
          <div class="close-intro-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.5836 1.19444L1.19472 22.5833M22.5836 22.5833L1.19472 1.19444" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>`
    };
    
    let graniteNames = {};
    granites.forEach(granite => {
      graniteNames[granite.reference] = t(`config3d_granit_${granite.reference}`) || granite.name
    });

    const monument = configuration.monument;
    const monumentComponent = getMonumentCategory();
    const isVeneer = (monument.category === "PLT")
    let footerLinks = this.props.footerLinks
    let graniteName = graniteNames[monument.graniteMain] +
      (monument.graniteSecondary && monument.graniteSecondary !== monument.graniteMain ? ` et ${graniteNames[monument.graniteSecondary]}` : '');

    switch (monument.category){
      case "SEM":
        graniteName = configuration.frame.granite? graniteNames[configuration.frame.granite]:graniteNames[monument.graniteMain]
        break;
      case "PLT":
        graniteName = configuration.veneer.granite? graniteNames[configuration.veneer.granite]:graniteNames[monument.graniteMain]
        break;
      default:
    }

    return (
      <div className="ConfigurationMonument">
        {steps.length > 0 && (
          <Steps
            ref={this.stepsRef}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onBeforeExit={this.onBeforeExit}
            onExit={this.onExit}
          />
        )}

        {!!this.state.viewTutorial && (<section className="Tutorial">
          <div className="Close" onClick={this.closeTutorial}>J'ai compris / Fermer le tutoriel</div>
          <p className="NavBarHelp">
            <strong>Suivez les étapes pour configurer votre monument</strong>
          </p>
          <p className="LeftButtonsHelp">
            <strong>Personnalisez chaque partie de votre monument</strong>
          </p>
          <p className="View360Help">
            <strong>Visualisez votre configuration à 360°</strong>
          </p>
          <p className="DocumentsHelp">
            <strong>Imprimez, téléchargez ou partagez avec la famille vos devis et rendus</strong>
          </p>
          <p className="DetailsHelp">
            <strong>Accédez au détail de vos choix chiffrés</strong>
          </p>
          <p className="PreviousStepHelp">
            <strong>Revenez une étape en arrière</strong>
          </p>
          <p className="NextStepHelp">
            <strong>Poursuivez votre personnalisation</strong>
          </p>
        </section>)}

        <ConfigurationIsoView config={configuration}  frame={monument.category === "SEM"} />
        {(isFromStock(this.props.configuration) && monument.category !== "ACC") && <div className="left-buttons Stock"> <FrameOptionPanel />
         <div className="StockFrameInfo">Sous réserve de semelle appareillable sur stock </div></div>}
        {(!isFromStock(this.props.configuration) || monument.category === "ACC") && <div className="left-buttons">
          {/* {isSmartphone &&<div> <LayoutOptionPanel /> <FrameOptionPanel /></div>} */}
        {!configurationOrdered(configuration) && <div className="left-buttons-container"> 
         {!(monument.category in monumentComponent) &&
           <>
           <HeadstonePicker 
            toggleSteps={this.toggleStepsInModal}
            launchIntro={this.launchIntro}
            madalName="HeadstonePicker"
           />
           <LayoutOptionPanel />
           <BaseHeightOptionPanel />
           </>
         }   
         {
          (monument.category === "SEM" && configuration.frame.mode) &&
          <LayoutOptionPanel />
         }     
            {
              (!(monument.category in monumentComponent) || (monument.category === "SEM" && configuration.frame.mode)) &&
              <FrameOptionPanel />
            }  
            {
              monument.category === "ACC" && <>
              <AccessoryPicker key="Vase" kind='Vase' buttonIcon="accessory-vase-icon" buttonClassName="btn-config-secondary text-dark config-button-background config-button-hover" />
              <AccessoryPicker key="Jardinière" kind='Jardinière' buttonIcon="accessory-box-icon" buttonClassName="btn-config-secondary text-dark config-button-background config-button-hover" />
              <AccessoryPicker key="Divers" kind='Divers' buttonIcon="other-icon"  buttonClassName="btn-config-secondary text-dark config-button-background config-button-hover" />
              <AccessoriesGranitePicker/>
              </>
            }
            {
              (!(monument.category in monumentComponent) || monument.category === "PLT") &&
              <VeneerOptionPanel isVeneer = {isVeneer}/>
            }
              {/* {(!(monument.category in monumentComponent) && !this.state.viewTutorial ) &&(<div className="OpenHelp">
                <button className="SquareButton"
                        title="Revoir le tutoriel du configurateur 3D"
                        onClick={this.openTutorial}>
                  <i className="icon material-icons">&#xE0C6;</i>
                </button>
              </div>)} */}

            {introIsAvailable("monument") &&
              <div className='d-flex flex-column align-items-center'>
                <span onClick={() => this.toggleSteps()} className="open-tutorial question-circle-icon icon icon-large mb-2"></span>
                <span className="info-tutorial text-dark ">visite guidée de la page</span>
              </div>
            }
          </div>
        }
        </div>}

        {!isSmartphone() && (
        <div className="right-buttons">
          <div className="right-buttons-container">
            <View3D />
            <Documents />
            <Details />
            <UndoRedo />
            <Flowers />
          </div>
        </div>
        )}

        <FooterWithRouter footerLinks={footerLinks}>
          <DefaultFooterTitle accessories={configurator.accessories} configuration={configuration} />
          {monument.category !=='ACC' && <p className="Granite">{graniteName}</p>}
          {isSmartphone() && (
            <div className="d-flex justify-content-around">
              <View3D />
              <Documents />
              <Details />
              <Flowers />
            </div>
            )} 
        </FooterWithRouter>
      </div>
    );
  }
}

export const ConfiguratorMonument = connect(
  (state) => ({
    user: state.user,
    configuration: state.configurator.current.configuration,
    granites: state.configurator.current.options.allgranites,
    configurator: state.configurator,
    catalog: state.catalog,
  }),
  (dispatch) => ({
    uiActions: bindActionCreators(uiActions, dispatch),
    tutorialActions: bindActionCreators(tutorialActions, dispatch),
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    configActions: bindActionCreators(configurationActions, dispatch),
  })
)(ConfiguratorMonumentComponent);
