import React, { Component } from 'react';
const loadingImage = require("../../../images/loader@4x.gif");

class Loader extends Component {
    render() {
        return (
            <div className='catalog-loader'>
                <img className="LoadingIcon" src={loadingImage} alt="Icône d'attente"/>
            </div>
        );
    }
}

export default Loader