import {getFs,getFsGcsUrl} from '../../../services/api.familyspace.service';
import {getStore} from '../../../store';
import * as api from '../../../services/api.service';
import { addDocs, imageConfigProject,storeImageConfigProject } from '../../../actions/familySpaceActions';
import * as apiService from '../../../services/api.service'
import { imgs } from '../../../services/images.service';
import defaultIcon from '../../../images/inhumation-icone.svg'
import * as types from '../../../actions/actionTypes';
import * as _ from "lodash";


export const getOwnerEmail = () => {
  const familyServiceInfo = getStore().getState().familyService;
  if (familyServiceInfo && familyServiceInfo.user && familyServiceInfo.user.email) {
    return familyServiceInfo.user.email;
  }
  return null;
}

export const  getFamilyInfo = async ()  => {
  const email = getOwnerEmail();
  if (email) {
    const familyInfo = await getFs(`/api/lastFamilyInfos/${email}`);
    if (familyInfo && familyInfo.newfamilyInfos) {
      return familyInfo.newfamilyInfos;
    }
  }
  return false;
}

export const isOwner = familyService => familyService.user && familyService.user.id && 
                                          familyService.familySpace && familyService.familySpace.member_id &&
                                          familyService.familySpace.member_id === familyService.user.id;


export const informFamily = async (clientId) => {
  const emailOwner = getOwnerEmail();
  if (emailOwner) {
    // Send an info to the space users that a project was added
    await api.post(`/api/familyspace/inform`,{infoType:"family_space_new_family_project_manual",
              sender:emailOwner,ref:"",email:emailOwner,clientId});
  }
}


export const saveFiles = async (files,author,familySpaceAction,context,idProject=null) =>  {

  const filesData = await Promise.all([].map.call(files, function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onloadend = function () {
            resolve({ data: reader.result, infos: {context, originalName: file.name, size: file.size, mimeType: file.type} });
        };
        reader.readAsDataURL(file);
    });
  }));
  for (let i=0; i<filesData.length;i++){
     await familySpaceAction.setFile({data: filesData[i].data, infos:filesData[i].infos, author, project_id: idProject});
  }
  return filesData;
}

export const checkMaxSize = (size) => ((size/1024)/1024).toFixed(4) > 2

export const isFamilyConfigProject = (project) => project && project.config_id && project.config_id.startsWith('4')
export const isClientConfigProject = (project) => project && project.config_id && project.config_id.startsWith('2')
export const isClientQuote = (project) => project && project.quote_id
export const isManual = (project) => project && (!project.quote_id && !project.config_id)

export const getQuoteDoc = (project,docs, storage)=>{
  if (isClientConfigProject(project) || isClientQuote(project)) {
    if(project.show_quote){
      if (docs && docs.selling){return ({iframe:true,url:docs.selling})}
    }else {
      const result = getFile(storage.files, project,'client_manual_quote');
      if (result) {return result}
    }
  }
  else if (isManual(project)){
    const result = getFile(storage.files, project,'project_quote_manual');
    if (result) {return result}
  }
  return false
}
const getFile =  (files,project, context) =>{
  if (files && files.length){
    const found = files.find(file=> file.project_id && file.project_id === project.id && file.context === context);
    if (found){ 
      const url =  getFsGcsUrl(found.space_id,`${found.uuid}.${found.ext}`);
      if (found.mime_type=== "application/pdf" || found.mime_type.split('/')[0] === "image"){
        return ({iframe:true,url})}
      else {return ({iframe:false,url})}
      }
  }
  return false
}

export const getPriceInfos = (project,  familyService)=>{
  const found = familyService.orgsInfos.find(item=>item.clientId === project.client);
  if (found){
    // "identity.organization.packageText" identity.organization.displayPackagePrice
    return ({identity:{organization:{packageText:found.packageText,displayPackagePrice:found.displayPackagePrice}}})
  }
  return false
}

export const  handleProject=async(isResearch=false,projects)=>{

  return await new Promise(async (resolve)=>{
     for(let i=0;i<=projects.length-1;i++){
      if(getStore().getState().familyService===false) break;
       let imageUrl = projects[i].config_id ? projects[i].configImage ? projects[i].configImage : await apiService.get(imgs.configuration.iso.large({reference: projects[i].config_id}))
       :projects[i].quote_id ? null: getFirstImage(projects[i].id);

       const docs = projects[i].docs ? projects[i].docs : await getAllDoscUrl(isResearch,projects[i]);
       if (projects[i].quote_id && docs && docs.texture){imageUrl = docs.texture}
    //    // On appelle une API différente de la configuration normale car les paramètres de l'organisation
       let config = null
       if ( projects[i].config_id ) {
       if ( projects[i].config ) { config = projects[i].config; }
       else {
       // Loading
      // await getStore().dispatch (imageConfigProject(isResearch,{ projectId: projects[i].id, loading: true, error: false, errorMsg: false }));

       // Request
       config = await apiService.get(`/api/configuration/${projects[i].config_id}`);

       }
      }

      // await  getStore().dispatch(storeImageConfigProject(isResearch,projects[i].id,imageUrl,config))
            projects[i]={...projects[i],configImage:imageUrl,docs,config,loading:false,error:false,errorMsg:false}
           }
            isResearch ?  
            await  getStore().dispatch({type: types.FS_UPDATE_SEARCHED_PROJECTS, payload:[...getStore().getState().familyService.searchedProjects,...projects]})
            :
            await  getStore().dispatch({type: types.FS_PROJECTS, payload:[...getStore().getState().familyService.projects,...projects]})
          
            let finalResult=isResearch ? getStore().getState().familyService.searchedProjects:getStore().getState().familyService.projects             
            if(finalResult.length) resolve(_.uniqBy(finalResult.map(item => item), e => e.id))

           
   })
  
 }

 async function getAllDoscUrl(isResearch,project) {
   // On récupère les liens sur les docs du json qui va bien
   const docLink = project.doc_link
     ? project.doc_link
     : project.config_id
     ? `${process.env.REACT_APP_DOCUMENTS_ORIGIN}/pro-space/configurations/${project.config_id}/documents.json`
     : null
   if (docLink) {
     let response = await fetch(docLink, {
       headers: Object.assign(
         {},
         { Accept: 'application/json', 'Content-Type': 'application/json' }
       )
     })
     if (response) {
       let responseJson = await response.json()
      //  await getStore().dispatch ( addDocs(isResearch,{
      //    id: project.id,
      //    docs: responseJson
      //  }))
       //Si le client ne veut pas montrer le devis on l'enève

       if (
         (project.show_quote === 0 || isFamilyConfigProject(project)) &&
         responseJson.selling
       ) {
         delete responseJson.selling
       }
       //this.setState({docs:responseJson});
       return responseJson
     }
   }
   return false
 }
 function getFirstImage(projectId, inputFiles = false) {
   const theFiles = inputFiles ? inputFiles : getStore().getState().familyService.files
   const files = theFiles.filter(
     (f) => f.project_id === projectId && f.mime_type.split('/')[0] === 'image'
   )
   if (files && files.length) {
     return getFsGcsUrl(files[0].space_id, `${files[0].uuid}.${files[0].ext}`)
   } 
   else {
     return defaultIcon
   }
 }
