import React, {Component} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as uiActions from '../../../actions/uiActions';
import {LazyImage} from '../../../components/LazyImage';
import * as format from '../../../services/format.service';
import {pricePackageToDisplay} from '../../../services/utils.service';
import {InputNumber} from '../../../components/InputNumber';
import {userCan} from '../../../services/userRights.service';
import { TranslationContext } from "../../../context/TranslationContext";
import ui from '../../../reducers/ui';
import * as _ from 'lodash';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import { isQuote } from '../../../services/restrictedConfiguration';
const cn = require('classnames');


export function priceFallback(user,price,considerPackage = true, acc = undefined) {
  const thePackage = considerPackage ? pricePackageToDisplay(user,price):0;
  //const qty = acc && acc.quantity ? acc.quantity : 1;
  return _.isUndefined(price.custom) || _.isNull(price.custom) ? (price.selling - thePackage) : (price.custom - thePackage)
}

class CompletionLineComponent extends Component {
  static contextType = TranslationContext;
  state = {isEditing: false, tmpPrice:0, quantity:this.props.accessory && this.props.accessory.quantity?this.props.accessory.quantity:1, maxQty:30}
  handleChange = (e,category ) => {
    const { value } = e.target;
    const { maxQty } = this.state;
    const qty = value > 0 ? value : 1;
    if (qty > maxQty){
      switch(category){
        case "SEM":
          alert("Vous ne pouvez pas commander plus de 30 unités par semelle.");
          break;
        case "ACC":
          alert("Vous ne pouvez pas commander plus de 30 unités par accessoires.");
          break;
      }
      
    }else{
      this.setState({quantity:qty})
      this.setQuantity(this.props,qty,category)
    }
  };
  componentDidMount(){
    const configuration = this.props.configuration
    const category = configuration.monument.category
    const isFrame = category ==='SEM' ? true:false;
    if(isFrame){
      const farmeQuantity = configuration.frame && configuration.frame.quantity? this.props.configuration.frame.quantity : 1
      this.setState({quantity: farmeQuantity})
    }
    
  }
  setQuantity = (props,qty,category) => {
    if(category === "ACC"){
      const acc = props.accessory
      const myAccessories = this.props.accessories;
      myAccessories.map((access,index)=>{
        if (access.id ===acc.id){
          this.props.updateConfigurationActions.updateQty('accessories.' + index, qty)
          return;
        }
      })
    }
    if(category === "SEM"){
      if(qty>0){
        this.props.updateConfigurationActions.updateQty('frame', qty)
        return;
      }
    }
  }
  priceChangedValue = (ev) => {
    ev = ev ? ev : this.state.tmpPrice ? this.state.tmpPrice : this.props.price ? priceFallback(this.props.user,this.props.price,this.props.accessory) : 0
    this.setState({tmpPrice: ev})
  }

  
  render() {
    const t = this.context;
    const { image, children, price, priceChange, removeItem, editItem,
            uiActions,allowNegative,className,user, isDetailsModal
          } = this.props;
    const {tmpPrice,quantity} = this.state;
    const isAddLine = this.props.isAddLine && this.props.isAddLine==true ? true : false;
    const priceChanging = () => {
      const {tmpPrice} = this.state;
      const mPrice = tmpPrice === 0 ? 0 : tmpPrice+packagePrice;
      priceChange((mPrice))
      this.setState({isEditing: false})
    }
    
    const {showBuyingPrices,showSellingPrices} = this.props.ui;
//dans cette version il semble que le prix puisse ne pas être défini dans ce cas on le met à 0 pour indiquer l'erreur
    const sellingPrice = price ? priceFallback(user,price,true,this.props.accessory) : 0;
    const packagePrice = pricePackageToDisplay(user,price);
    const configuration = this.props.configuration
    const category = configuration.monument.category
    const isAccessory = category ==='ACC' ? true:false;
    const isFrame = category ==='SEM' ? true:false;
    const item = this.props.accessory
    const isAccessoryItem = item && item.reference && item.reference.startsWith('A') ? true : false;
    const {maxQty} = this.state;

    const priceEmptyLine = configuration.additional.lines.filter(line=>line.price.custom===0)
    if (priceEmptyLine && priceEmptyLine.length>0){
      uiActions.addMessage({
        id: 'additional-line-price-0',
        className: 'warning',
        innerHTML: 'Le prix de vente n\'est pas renseigné sur une ou plusieurs lignes.'
      });
    }else{
      uiActions.removeMessage('additional-line-price-0')
    }

    const containerClasses = cn('Description', { disabled: !editItem, clickable: !isAddLine && editItem, 'd-flex': !isDetailsModal, 'flex-column': ((isAccessory && isAccessoryItem ) || isFrame)});
    
    let stockAccessoryMsgChild = null;
    let otherChildren = [];
    React.Children.forEach(children, child => {
      if (!child) return 0
      if (child.key === "stockAccMsg") {
        stockAccessoryMsgChild = child;
      } else {
        otherChildren.push(child);
      }
    })
    
    let lineRendring = "";
    { lineRendring = isDetailsModal ? (<>
        <div className={cn('Line', className)}>
          <div className="row mx-0">
            <div className='col-2 position-relative'>
              <div
                className={cn('RemoveItem position-absolute', {disabled: !removeItem})}
                disabled={!removeItem}  
                onClick={removeItem ? removeItem : undefined}>
                <span className="remove2-icon icon icon-large"></span>
              </div>
              <div className="Image">
                { isQuote(configuration) && this.props.accessory && this.props.accessory.required ?
                  <></> :
                  <>
                    {image && (_.isString(image) 
                      ? (<LazyImage className="FullSize" src={image} lazy={false}/>)
                      : <div className="CustomImageContent">{image}</div>
                    )}
                  </>
                }
              </div>
            </div>

            <div className='col-6'>
              <div className={containerClasses}
                disabled={!editItem} 
                onClick={(!isAddLine && editItem && !isAccessory && !isFrame) ? editItem : undefined}>
                {otherChildren} 
                {(((isAccessory && isAccessoryItem ) || isFrame) && this.props.showQuantity) && (
                  <>
                    <div className="block quantity">
                      <button className="btn-control minus" 
                        onClick={()=>{
                          const qty = this.state.quantity > 1 ? this.state.quantity-1 : 1
                          this.setState({quantity:qty})
                          this.setQuantity(this.props,qty,category)
                        }}>-</button>
                      <input type="text" name="quantity" placeholder="Qté: Ex: 1" value={this.state.quantity} onChange={e => this.handleChange(e,category)} />
                      <button className="btn-control plus" onClick={()=>{
                        let qty = this.state.quantity
                        if ((isAccessory || isFrame) && qty < maxQty) {
                          qty++
                          this.setState({quantity:qty})
                          this.setQuantity(this.props,qty,category)
                        } else { alert("Vous ne pouvez pas commander plus de "+maxQty+" unités."); }
                      }}>+</button>
                      {stockAccessoryMsgChild} 
                    </div>
                  </>)}
              </div>
            </div>
            
            <div className='col-4'>
              <div className="Prices row">
                <div className='col-6'>
                  {userCan.seeBuyingPrices() && (
                    <div className={cn("Cell BuyingPrice", {show: (showBuyingPrices && showSellingPrices)})}>
                      {price && typeof(price.buying) == 'number' && (
                        <div>
                          { !!price.buying ? (<span className='price'>
                            {format.price(price.discounted || Math.ceil(price.buying))}&nbsp;€
                            <small> { t("config3d_finalize_details_excl-vat") || "HT" }</small>
                          </span>) : !this.props.imprint && (<span className="price">{t("config3d_finalize_details_on-demand") || "Sur demande"}</span>) }
                          {typeof(price.discounted) == 'number' && price.discounted !== price.buying && (
                            <span className="Discount">{format.price(Math.ceil(price.buying))}&nbsp;€ <span className="DiscountRate">-{Math.round(price.discountRate * 100)}%</span></span>
                          )}
                          {(priceChange || typeof(price.selling) == 'number')  && !!price.buying && ((<></>) || (<div><strong>{t("config3d_finalize_details_on-demand") || "Sur demande"}</strong></div>))}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  {userCan.seeAndChangeSellingPrices() && (
                    <div className={cn("Cell SellingPrice", {show: showSellingPrices})}>
                      {priceChange ?
                        (this.state.isEditing && !userCan.cannotChangeSellingPrices() ? (
                          <div className="Field editing">
                            <div className="ToggleEditButton" onClick={()=>this.setState({isEditing: false, tmpPrice: 0})}>
                              <i className="icon material-icons">&#xE5CD;</i>
                            </div>
                            <div className="FieldContainer">
                              <InputNumber step={1} placeholder="" onValueChange={ev => this.setState({tmpPrice: ev || 0})}
                                          value={(tmpPrice===0 ? ((price && Math.round(priceFallback(user,price,this.props.accessory))>=0 && Math.round(priceFallback(user,price,this.props.accessory))) || null) : tmpPrice)}
                                          min={allowNegative ? '-99999' : '1'}/>
                              <button className="Save" onClick={()=>priceChanging()}>{t("config3d_finalize_misc_ok") || "OK"}</button>
                            </div>
                          </div>
                        ) : (
                          <div className="Field viewing">
                            <div className="ViewContainer ">
                              <strong className="price text-dark">{(isAddLine && sellingPrice != 0) || (!isAddLine && sellingPrice>0) ? format.price(sellingPrice) : isAddLine ? format.price(0) : '---'} €
                                <small> {t("config3d_finalize_details_vat") || "TTC"} {packagePrice? <span className="asterisque">*</span>:<span/>}</small>
                              </strong>
                            </div>

                            {!this.props.imprint && !userCan.cannotChangeSellingPrices() && (<span className="ToggleEditButton" onClick={()=>this.setState({isEditing: true})}>
                              <i className="icon material-icons">&#xE254;</i>
                            </span>)}
                          </div>
                        )) 
                      : (
                          <strong>{(isAddLine && sellingPrice != 0) || (!isAddLine && sellingPrice>0) ? format.price(sellingPrice) : isAddLine ? format.price(0) : '---'} €
                            <small> TTC {packagePrice? <span className="asterisque">*</span>:<span/>}</small>
                          </strong>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        </>
      ) : (
        <div className={cn('Line border-color-bottom', className)}>
            <div className={containerClasses}
              disabled={!editItem} 
              onClick={(!isAddLine && editItem && !isAccessory && !isFrame) ? editItem : undefined}>
              {otherChildren} 
              {(((isAccessory && isAccessoryItem ) || isFrame) && this.props.showQuantity) && (
                <>
                  <div className="block quantity">
                    <button className="btn-control minus" 
                      onClick={()=>{
                        const qty = this.state.quantity > 1 ? this.state.quantity-1 : 1
                        this.setState({quantity:qty})
                        this.setQuantity(this.props,qty,category)
                      }}>-</button>
                    <input type="text" name="quantity" placeholder="Qté: Ex: 1" value={this.state.quantity} onChange={e => this.handleChange(e,category)} />
                    <button className="btn-control plus" onClick={()=>{
                      let qty = this.state.quantity
                      if ((isAccessory || isFrame) && qty < maxQty) {
                        qty++
                        this.setState({quantity:qty})
                        this.setQuantity(this.props,qty,category)
                      } else { alert("Vous ne pouvez pas commander plus de "+maxQty+" unités."); }
                    }}>+</button>
                    {stockAccessoryMsgChild} 
                  </div>
                </>)}
            </div>

            <div className="PricesBlock">
              <div className="Prices">
                  
                {userCan.seeAndChangeSellingPrices() && (
                  <div className={cn("Cell SellingPrice", {show: showSellingPrices})}>
                    {priceChange ?
                      (this.state.isEditing && !userCan.cannotChangeSellingPrices() ? (
                        <div className="Field editing">
                          <div className="ToggleEditButton" onClick={()=>this.setState({isEditing: false, tmpPrice: 0})}>
                            <i className="icon material-icons">&#xE5CD;</i>
                          </div>
                          <div className="FieldContainer">
                            <InputNumber step={1} placeholder="" onValueChange={ev => this.setState({tmpPrice: ev || 0})}
                                        value={(tmpPrice===0 ? ((price && Math.round(priceFallback(user,price,this.props.accessory))>=0 && Math.round(priceFallback(user,price,this.props.accessory))) || null) : tmpPrice)}
                                        min={allowNegative ? '-99999' : '1'}/>
                            <button className="Save" on Click={()=>priceChanging()}>{t("config3d_finalize_misc_ok") || "OK"}</button>
                          </div>
                        </div>
                      ) : (
                        <div className="Field viewing d-flex justify-content-end">
                          <div className="ViewContainer">
                            <strong className="text-dark">{(isAddLine && sellingPrice != 0) || (!isAddLine && sellingPrice>0) ? format.price(sellingPrice) : isAddLine ? format.price(0) : '---'} 
                              {/* € <small> {t("config3d_finalize_details_vat") || "TTC"} {packagePrice? <span className="asterisque">*</span>:<span/>}</small> */}
                            </strong>
                          </div>
                        </div>
                      )) :
                      <div className='d-flex justify-content-end'>
                        <strong>{(isAddLine && sellingPrice != 0) || (!isAddLine && sellingPrice>0) ? format.price(sellingPrice) : isAddLine ? format.price(0) : '---'} 
                          {/* € <small> TTC{packagePrice? <span className="asterisque">*</span>:<span/>}</small> */}
                        </strong>
                      </div>
                      }
                  </div>
                )}

              </div>
            </div>

            {/* <div className="d-flex flex-column justify-content-start mt-1">
              { isQuote(configuration) && this.props.accessory && this.props.accessory.required 
              ? <></> 
              : <>
                  {showSellingPrices &&
                  (<div 
                    className={cn('ToggleEditButton', {disabled: !editItem})}
                    onClick={()=>this.setState({isEditing: true})}>
                    <span className="edit-icon icon icon-large"></span>
                  </div>)}

                  <div
                    className={cn('RemoveItem', {disabled: !removeItem})}
                    disabled={!removeItem} onClick={removeItem ? removeItem : undefined}>
                    <span className="remove2-icon icon icon-large"></span>
                  </div>
                </>
              }
            </div> */}
        </div>
      )}

    return lineRendring;
  }
}

export const CompletionLine = connect((state) => ({
  ui:state.ui,
  user:state.user,
  configuration: state.configurator.current.configuration,
  accessories: state.configurator.current.configuration.accessories}),
  dispatch => ({
    uiActions: bindActionCreators(uiActions, dispatch),
    updateConfigurationActions: bindActionCreators(updateConfigurationActions, dispatch),
  }))(CompletionLineComponent);
