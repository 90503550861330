import React, {Component} from "react";
import history from "../history";
import {ListGroup,ListGroupItem} from "reactstrap";
import {userCan} from '../services/userRights.service';



export class AdminMenu extends Component {
  state={clicked:false}

  selectItems = () => {
    const itemsMenu = [{value:"/adminfamily/dashboard",label:"Tableau de bord famille",test:userCan.configAdmin() || userCan.clientAdminFamily()},
                 {value:"/adminfamily/list",label:"Admin config famille",test:userCan.configAdmin()},
                 {value:"/admin/catalog",label:"Configuration monuments",test:userCan.configAdmin()}];
                 return itemsMenu.filter(item=>item.test);
  }

  selectItem = (item) => {
    history.push(item)
    const {loadingPrices} = this.props;
    if (loadingPrices) { loadingPrices(); }
  }

  render() {
    const items = this.selectItems();
    const {clicked} =  this.state;
    return (
      (userCan.configAdmin() || userCan.clientAdminFamily()) && 
        <div className="AdminSelect">
          <i className="icon material-icons" onClick={()=>this.setState({clicked:!clicked})}>settings</i>
          {clicked && <ListGroup>
            {items.map((item,index)=> item.test && 
            <ListGroupItem className="AdminSelectItem" key ={index} onClick={() => this.selectItem(item.value)}>{item.label}</ListGroupItem>)}
          </ListGroup> 
          }
       </div>
    )}
}
