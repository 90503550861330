import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal } from "../../components/Modal";
import { SearchBox } from "./components/SearchBox";
import { MonumentList } from "./components/MonumentList";
import { MonumentCarousel } from "./components/MonumentCarousel";
import { CatalogFooter } from "./components/Footer";
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as catalogActions from "../../actions/catalogActions";
import * as userActions from "../../actions/userActions";
import * as configurationActions from "../../actions/configurationActions";
import { scrollPageTo } from "../../services/domFunctions";
import { AdminMenu } from "../../components/AdminMenu";
import {AccessoryList} from "./components/AccessoryList";
import Loader from "./components/Loader";
import {userCan} from '../../services/userRights.service';
import * as apiService from "../../services/api.service";
import {getMonumentImageByGranit, createMonumentImage} from '../../services/image.fct.service';
import history from "../../history";

const loadingImage = require("./../../images/loader@4x.gif");



function detectIOS() {
  const ua = window.navigator.userAgent;
  const ios = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
  return ios;
}

class CatalogComponent extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      load: PropTypes.func.isRequired,
      search: PropTypes.func.isRequired,
      selectMonument: PropTypes.func.isRequired,
      closeMonument: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
      firstLoad: true,
      displayScrollToTop: false,
      loadingSelectedMonument: false,
      loadingPrices: true,
      showPrices: '',
      monumentsWithPrices: [],
      visibleMonumentsReferences: [],
      priorityMonumentsReferences: [],
      organizationReference: '',
      maxmonumentprice: 0,
      refreshPrices: false,
      granit: null,
      color: null,
      oldVisibleMonuments: [],
    };

  async componentDidMount () {
    //document.body.classList.add('body-background');
    const payload={query: '',accessories:[]};
    payload.accessories = await apiService.get('/api/catalog/accessories');
    this.props.actions.searchAccessories(payload);
  }
  
  async refreshData() {
    if(Object.keys(this.state.oldVisibleMonuments).length){
      this.props.catalog.visibleMonuments.map(monument => {
        monument.graniteMain.reference = this.state.oldVisibleMonuments[monument.reference]["graniteMain"];
        monument.graniteMain.name = this.state.oldVisibleMonuments[monument.reference]["graniteMainName"];
        monument.graniteMainId = this.state.oldVisibleMonuments[monument.reference]["graniteMainId"];
        monument.image = this.state.oldVisibleMonuments[monument.reference]["image"];
      })
    }else{
      getMonumentImageByGranit(this.props.catalog.visibleMonuments, 0, 'large').then(images => {
        return this.props.catalog.visibleMonuments.map(monument => {
          const colorGranites = Object.values(monument["colorGranites"])
          let granite = monument.graniteMain.reference
          let granites = {}
          colorGranites.forEach(color => {
            granites = Object.assign(granites, color);
          });
          const granites_refs = Object.keys(granites)
          //
          if(!granites_refs.includes(granite) && granites_refs.length) {
            granite = granites_refs[Math.floor(Math.random()*granites_refs.length)];
            monument.graniteMain.reference = granite
            monument.graniteMain.name = granites[granite]['name']
            monument.graniteMainId = granites[granite]['id']
            const monuImgs = images[monument.reference]
            if (typeof monuImgs === "object" && granite in monuImgs) {
              monument.image = monuImgs[granite]
            } else {
              monument.image = createMonumentImage(monument, granite, 'large')
            }
          }
          return monument;
        })
      }).then(monuments => {
        const visible_monuments = {};
        monuments.forEach(monument => {
          visible_monuments[monument.reference] = {}
          visible_monuments[monument.reference]["graniteMain"] = monument.graniteMain.reference;
          visible_monuments[monument.reference]["graniteMainName"] = monument.graniteMain.name;
          visible_monuments[monument.reference]["graniteMainId"] = monument.graniteMainId;
          visible_monuments[monument.reference]["image"] = monument.image;
        })
        this.setState({"oldVisibleMonuments": visible_monuments})
      })
    }
  }

  async componentDidUpdate () {

    if(this.state.firstLoad && this.props.catalog.visibleMonuments.length){
      this.setState({firstLoad: false})
      await this.refreshData()
     }

    // check the role of user is buyer or not
    const _showPrices = Boolean(JSON.parse(new URLSearchParams(this.props.location.search).get("isItBuyer"))) && userCan.fullPrice();
    if (this.state.showPrices === '' && _showPrices){
      this.setState({ showPrices: _showPrices });
    }

    // get prices
    const _refMonuments = this.props.catalog.visibleMonuments.map(monument => monument.reference);

    if(this.state.showPrices && this.props.user && this.props.user.identity){
      if ((!this.state.organizationReference || this.state.refreshPrices) && this.state.visibleMonumentsReferences.length != 0){
        this.setState({refreshPrices: false});
        const orgRef = this.props.user.identity.organization.reference;
        this.setState({ organizationReference: orgRef});
        this.getprices(this.state.visibleMonumentsReferences, orgRef);

      } else if (this.state.visibleMonumentsReferences.length != _refMonuments.length) {

        const _refMonumentsWithPrices = this.state.monumentsWithPrices.map(monument => monument.reference);
        const _refNotExist = _refMonuments.filter(value => !_refMonumentsWithPrices.includes(value));
        const _priorityMonumentsReferences = this.state.priorityMonumentsReferences;
        const is_same = (_refNotExist.length == _priorityMonumentsReferences.length) && _refNotExist.every(function(element, index) {
          return element === _priorityMonumentsReferences[index]; 
        });
        if (_refNotExist.length > 0 && !is_same) {
          this.setState({ priorityMonumentsReferences: _refNotExist});
          this.getprices(_refNotExist, this.state.organizationReference);
        }
      }
    }
  }

  getprices = async (__refMonuments, _orgRef) => {
    // Gat max monument price per page
    if(this.state.maxmonumentprice == 0){
      const _maxmonumentprice = await apiService.get(`/api/catalog/maxmonumentprice`);
      this.setState({ maxmonumentprice: _maxmonumentprice });
    }
    const per_page = this.state.maxmonumentprice;
    const total_pages = Math.ceil(this.props.catalog.visibleMonuments.length / per_page);
    const _granit = this.state.granit ? '&granit=' + this.state.granit : '';
    this.setState({ monumentsWithPrices: []})
    for (let index = 1; index <= total_pages; index++) {
      if(this.state.showPrices && this.state.loadingPrices){
        // get references and monuments
        let dataMonumentsReferences = this.paginator(__refMonuments, index, per_page);
        let dataReferencesGranites  = this.props.catalog.visibleMonuments.filter(monument => dataMonumentsReferences.includes(monument.reference)).map(monument => monument.reference +'-'+ monument.graniteMain.reference) 
        await apiService.get(`/api/catalog/monuments?fullprice=1&client=${_orgRef}&references=${dataMonumentsReferences.toString()}&granits=${dataReferencesGranites.toString()}`).then(monuments => {
          this.setState({ monumentsWithPrices: [...this.state.monumentsWithPrices, ...monuments] });
        }).catch(err => console.log(err));
      }
      // break;
    }
  }

  paginator(items, current_page, per_page_items) {
    let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,
    paginatedItems = items.slice(offset).slice(0, per_page_items);
    return paginatedItems;
  }

  onSearch = async payload => {
    // this.setState({granit: (payload.granit? payload.granit.reference: null), refreshPrices: true});
    let pld = payload ? payload : {}
    this.props.actions.search(payload,this.props.config);
    pld.query = pld.query ? pld.query : ''
    pld.accessories = pld && ((pld.category && pld.category==='ACC') || pld.query!=='' || pld.query !== undefined ) ? await apiService.get('/api/catalog/accessories') : [];
    this.props.actions.searchAccessories(pld);
  };

  onGraniteColorChange = async color => {
    this.setState({color: (color? color: null), granit: null});
    if (color) {
      await getMonumentImageByGranit(this.props.catalog.visibleMonuments, 0, 'large').then(images => {
        this.props.catalog.visibleMonuments.map(monument => { 
          const monuImgs = images[monument.reference]
          let granite = monument.graniteMain.reference
          if(color in monument["colorGranites"]){ 
            const _granitsAvailable = Object.keys(monument["colorGranites"][color])
            if (_granitsAvailable.length) {
              granite = _granitsAvailable[Math.floor(Math.random()*_granitsAvailable.length)];
              monument.graniteMain.reference = granite
              monument.graniteMainId = monument["colorGranites"][color][granite]['id']
              monument.graniteMain.name = monument["colorGranites"][color][granite]['name']
            }
          }
          if (typeof monuImgs === "object" && granite in monuImgs) {
            monument.image = monuImgs[granite]
          } else {
            monument.image = createMonumentImage(monument, granite, 'large')
          }
          return monument;
        })
      })
    }else{
      await this.refreshData()
    }
    this.setState({refreshPrices: true})
  }

  onGraniteChange = async granit => {
    this.setState({granit: (granit? granit.reference: null), color: null});
    if (granit) {
      await getMonumentImageByGranit(this.props.catalog.visibleMonuments, granit.reference, 'large').then(images => {
        this.props.catalog.visibleMonuments.map(monument => {
          monument.image = images[monument.reference]
          monument.graniteMain.reference = granit.reference
          monument.graniteMain.name = granit.name
          monument.graniteMainId = granit["id"]? granit["id"]: monument.graniteMainId
          return monument;
        })
      })
    }else{
      await this.refreshData()
    }
    this.setState({ refreshPrices: true})
  };

  onFilter = (payload) => {
    this.props.actions.filter(payload);
  };

  onSelectMonument = monument => {
    this.props.actions.selectMonument(monument);
  };
  initConfiguration = (monument) => {
    const {configurationActions} = this.props;
    this.setState({
      initializing: true,
    });
    configurationActions.initConfiguration(monument,this.props.config);
  };
  onSelectAccessory = (accessory,granite) => {
    this.props.catalog.selectedAccessory = accessory;
    this.props.catalog.selectedAccessoryGranite = granite;
    // those two lines at the top doesn't do anything
    this.initConfiguration("ACC");
    // this.props.accActions.addAccessory(accessory, granite);
  };
  closeModal = () => {
    this.props.actions.closeMonument();
  };

  componentWillMount() {
    const {family} = this.props.user;
    // Charge le catalogue de monuments
    this.props.actions.load(family);
    if (!family){
      this.props.userActions.getUserConfig();
    }
    // Recharge l'utilisateur pour avoir la dernière configuration modifiée
    // this.props.userActions.load();

    this.onReceiveProps(undefined, this.props);
    
    // Redirect to /admin/catalog
    const searchParams1 = new URLSearchParams(this.props.history.location.search);
    const ipage = searchParams1.has("ipage")? searchParams1.get("ipage") : '';
    if (ipage == "adminfamilyDashboard") {
      history.push('/adminfamily/dashboard?adminfamilyDashboard=true')
    } else if (ipage == "adminfamilyList") {
      history.push('/adminfamily/list?adminfamilyList=true')
    } else if (ipage == "adminCatalog") {
      history.push('/admin/catalog?adminCatalog=true')
    } else if (ipage == "adminfamilyNewPreconfig") {
      const monumentRef = searchParams1.has("monumentRef")? searchParams1.get("monumentRef") : '';
      history.push(`/configuration/${monumentRef}?adminfamilyNewPreconfig=true`)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.onReceiveProps(this.props, nextProps);
  }

  checkEntryParam = (props,param) =>{
    const params = new Map(props.location.search.slice(1).split('&').map(kv => kv.split('=')));
    const value = params.has(param)?params.get(param):null;
    return value
  }
  onReceiveProps(oldProps = { loading: true }, props) {
    if (oldProps.loading === props.loading) {
      // Si les infos ne sont pas encore chargés ou elles le sont
      // et on a déjà traiter le filtre sur les query params, on ne fait rien
      return;
    }

    const collection = this.checkEntryParam(props,"collection");
    if (collection) {
      switch (collection) {
        case "star":
          props.actions.search({
            favorites: props.favorites
          },this.props.config);
          break;
        case "featured":
          props.actions.search({isFeatured: true});
          break;
        case "new":
          props.actions.search({isNew: true});
          break;
        case "exclusive":
        props.actions.search({exclusive: true});
        break;          
        default:
          props.actions.search({category: collection});
          break;
      }
    }
  }

  onListScroll = top => {
    this.setState({
      displayScrollToTop: top > -250
    });
  };

  scrollToTop = () => {
    scrollPageTo(0);
  };

  hidePrices = () => {
    this.setState({ showPrices: false });
  }

  loadingPrices = () => {
    this.setState({ loadingPrices: false });
  }

  render() {
    // if (this.props.loading) {
    //   return null;
    // }
    const _monumentsPrices = this.state.monumentsWithPrices.reduce((r, o) => (r[o.reference] = o.fullprice, r), []);
    const collection = this.checkEntryParam(this.props,"collection");
    const previewLastConfiguration = "true";
    const displayScrollToTopButton = this.state.displayScrollToTop && !collection;
    const search = this.props.catalog.search || {};
    const filters = this.props.catalog.filters;
    let category =
      collection ||
      search.category ||
      (search.favorites && "star") ||
      (search.isNew && "new") ||
      (search.query && "search");

    let listTitle;
    switch (category) {
      case "400-499":
        listTitle = "Nos monuments contemporains";
        break;
      case "500-599":
        listTitle = "Nos monuments plats";
        break;
      case "600-699":
        listTitle = "Nos monuments classiques";
        break;
      case "700-799":
        listTitle = "Nos monuments doubles";
        break;
      case "800-899":
        listTitle = "Nos monuments musulmans et israëlites";
        break;
      case "CIN":
        listTitle = "Nos monuments cinéraires";
        break;
      case "TBL":
        listTitle = "Nos tombales";
        break;
      case "star":
        listTitle = "Vos favoris";
        break;
      case "new":
        listTitle = "Nos nouveautés";
        break;
      case "search":
        listTitle = "Résultat(s) de recherche";
        break;
      default:
        listTitle = "Tous nos monuments";
        break;
    }
    const featuredMonuments = ((collection !== "featured" && collection !== "new" &&  this.props.catalog.visibleMonuments) || []).filter(
      ({ isFeatured }) => isFeatured
    );
    let visibleMonuments = this.props.catalog.visibleMonuments
    const visibleAccessories = this.props.catalog.visibleAccessories
    if(featuredMonuments.length > 0) {
      visibleMonuments = ((collection !== "featured" && collection !== "new" && this.props.catalog.visibleMonuments) || [])
      .filter(({ isFeatured }) => !isFeatured)
    }
    if (this.state.visibleMonumentsReferences.length == 0 && visibleMonuments.length != 0){
      const _featuredMonuments = featuredMonuments.map(monument => monument.reference);
      const _visibleMonuments = visibleMonuments.map(monument => monument.reference);
      this.setState({ visibleMonumentsReferences: [..._featuredMonuments, ..._visibleMonuments] });
    }

    const searchQuery = search && search.query ? search.query : "";

    // Affiche le loader si le catalogue n'est pas chargé ou si on attend la réponse du serveur (wait monuments & latestconfig)
    if (this.props.loading || this.state.loadingSelectedMonument) {
      return <Loader />;
    }
    
    return (
      <div className="Catalog">
        <AdminMenu loadingPrices={this.loadingPrices} />
        {!collection  && <SearchBox onSearch={this.onSearch} onGraniteChange={this.onGraniteChange} onGraniteColorChange={this.onGraniteColorChange} favorites={this.props.favorites} config={this.props.config} onFilter={this.onFilter} catalog={this.props.catalog} userRight ={this.props.user.id} />}

        {// N'affiche pas la liste sur iOS lorsque la modale est affichée, afin éviter le scroll dans l'iframe et la position fixed
        (!detectIOS() || this.props.catalog.selectedIndex === null) && (
          <div id="CatalogList">
            {!!featuredMonuments.length && (
              <div>
                <h2 className="ListTitle ListTitle-Featured">Les incontournables</h2>
                <MonumentList
                  monuments={featuredMonuments}
                  onSelect={this.onSelectMonument}
                  onScroll={this.onListScroll}
                  showPrices={this.state.showPrices}
                  prices={_monumentsPrices}
                />
                <h2 className="ListTitle">{listTitle}</h2>
              </div>
            )}
            {visibleMonuments && visibleMonuments.length > 0 && <MonumentList monuments={visibleMonuments} onSelect={this.onSelectMonument} onScroll={this.onListScroll} showPrices={this.state.showPrices} prices={_monumentsPrices}/>}
            {visibleAccessories && visibleAccessories.length > 0 && (search.category==='ACC' || searchQuery !=='') && filters.layouts[0] === "all" && userCan.accessoryConfig() && <AccessoryList accessories={visibleAccessories} onSelect={this.onSelectAccessory} onScroll={this.onListScroll}/>}
          </div>
        )}

        {previewLastConfiguration === "true"  && <CatalogFooter displayScrollToTopButton={displayScrollToTopButton} />}

        <button
          className={`scrollToTopButton ${displayScrollToTopButton ? "active" : ""} ${
            previewLastConfiguration === "true" ? "offset" : ""
          }`}
          onClick={this.scrollToTop}
        >
          <strong>Haut de page</strong>
          <small>retourner au menu</small>
        </button>

        {this.state.showPrices && <div className="showPrices">
        <i className="icon material-icons">&#xe002;</i> Les prix de vente et d’achat sont affichés. <a onClick={this.hidePrices} >Masquer les prix</a>
        </div>}

        <Modal
          isOpen={this.props.catalog.selectedIndex !== null }
          onRequestClose={this.closeModal}
          overlayClassName="CatalogModalOverlay"
          className="CatalogModal"
        >
          <MonumentCarousel slideToShow={this.props.catalog.selectedIndex} showPrices={this.state.showPrices} prices={_monumentsPrices} organizationReference={this.state.organizationReference} granit={this.state.granit} loadingPrices={this.loadingPrices}/>
        </Modal>
      </div>
    );
  }
}

export const Catalog = connect(
  state => ({
    catalog: state.catalog,
    favorites: state.user.favorites,
    allAccessories: state.configurator.accessories,
    loading: state.catalog.loading || !state.user.id,
    user:state.user
  }),
  dispatch => ({
    actions: bindActionCreators(catalogActions, dispatch),
    accActions: bindActionCreators(updateConfigurationActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    configurationActions: bindActionCreators(configurationActions, dispatch),
  })
)(CatalogComponent);
