import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as apiService from "../../services/api.service";
import history from "../../history";
import {imgs} from '../../services/images.service';
import {getMonumentImage} from '../../services/image.fct.service';
import * as configurationActions from '../../actions/configurationActions';
import * as catalogActions from '../../actions/catalogActions';
import { SearchBox } from "../catalog/components/SearchBox";
import * as _ from "lodash";
import arrayMove from 'array-move';
import { SortableMonuments } from './components/AdminSortableMonuments';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import loader from '../../images/loader@4x.gif';
import PopupConfirm from '../../components/PopupConfirm'
class AdminCatalogComponent extends Component {
  state = {
    started:false,selected:false,
    preconfList: [],commonList:[],
    visibleMonuments:[],
    refreshOngoing:false,
    items: Array.apply(null, Array(100)).map((val, index) => `Item ${index}`),
    isLoading:false,
    errorMessage:"",
    showConfirm:false,
    resetOrderConfirm:false,
    methodConfirm:"",
    // catalogs:[],
    // catalogSelected:{},
    // forceMethodUpdate:false,
  };
  async componentDidMount(){
    // const catalogs = await apiService.get(`/api/catalog/clientcatalogs`) 
    // const catalogSelected  = ((catalogs.length)? { value: catalogs[0].internal_id, label: catalogs[0].name }: {})
    // const catalog = catalogSelected? (catalogSelected.value? 'catalog='+catalogSelected.value: ''): ''
    const preconfList = await apiService.get(`/api/admin/monuments`) 
    const visibleMnts = await apiService.get(`/api/catalog/monuments?withoutCatalog=1`) 
    this.setState({preconfList, started:true, visibleMonuments: visibleMnts});
  }
  async combineList(){
    const visibleMnts = this.state.visibleMonuments.map(monument => { 
      monument.clientConfig = "";
      monument.familyConfig = "";
      monument.imageClient = "";
      monument.imageFamily = "";  
      return monument;
    })
    let commonList =[...visibleMnts];
    this.state.preconfList.forEach(config=>{
      let index = commonList.findIndex(item=>item.reference === config.reference);
      if (index!==-1){
        commonList[index].clientConfig = config.clientConfig;
        commonList[index].familyConfig = config.familyConfig;
        commonList[index].imageClient = config.imageClient;
        commonList[index].imageFamily = config.imageFamily;        
      }
    })
    this.setState({commonList})
  }
  componentDidUpdate(prevProps){
    if ((!this.props.catalog.loading  &&  this.state.started )||
         (this.props.catalog.search !== prevProps.catalog.search)){
      this.combineList();
      this.setState({started:false})
    }
  }

  customContentRender = ({props, state}) => {
    return <div className="custom-content-render">
            {`${props.values.length} monuments surchargés sur ${this.props.catalog.monuments.length}`}
          </div>;
  }
 
  updateConfig= (id)=> {history.push(`/configuration/${id}`)};
  deleteConfig = async (id,type,monument) =>{
    await apiService.del(`/api/admin/monument/${monument}/${id}/${type}`);
    const cloneList =  _.cloneDeep(this.state.commonList);
    const foundIndex = cloneList.findIndex(item=>item.reference === monument); 
    if (foundIndex !== -1){
      if (type === "both" || type ==="client"){
        cloneList[foundIndex].clientConfig = "";
        cloneList[foundIndex].imageClient = "";
      }
      if (type === "both" || type ==="family"){
        cloneList[foundIndex].familyConfig = "";
        cloneList[foundIndex].imageFamily = "";
      }      
      this.setState({commonList:cloneList})
    }
  }
  addConfig = async (type,ref=false) =>{
    //Création d'une configuration de forme catalog

    const monumentRef = ref ? ref : this.state.selected;

    const data = await apiService.post(`/api/configuration`, {monumentRef,configType:"catalog"});
    if (data.reference){

      let params={reference:monumentRef}

      //  if("value" in this.state.catalogSelected){ params.catalog = this.state.catalogSelected.value }
       if (type === "duo" || type ==="client"){ 
        params.clientConfig = data.reference
        params.graniteMainClient = data.granite
        params.secondaryGraniteClient = data.secondarygranite
        params.frameClient = {"heights": data.frame.heights, "mode": data.frame.mode}
        params.preconfHash = data._id
        params.updatedAt = data.updatedAt
        params.accessory = ""
      };
       if (type === "duo" || type ==="famille"){ 
        params.familyConfig = data.reference
        //params.graniteMainFamily = data.granite
        //params.frameFamily = {"heights": data.frame.heights, "mode": data.frame.mode}
      };

      await apiService.post(`/api/admin/monument`, params);
      history.push(`/configuration/${data.reference}`);
    }
    this.setState({selected:false});
  }

  //On affich en priorité l'image Client sinon l'image famille
  getImage =  (monument) =>  {
    return monument.imageClient ? monument.imageClient : monument.imageFamily ? monument.imageFamily : getMonumentImage(monument,"thumbnail");
  }

  onSearch = payload => {
    this.props.cataActions.search(payload,this.props.config);
  };
  
  onFilter = (payload) => {    
    this.props.cataActions.filter(payload);
  };
  
  refreshImage = async (item) => {
    this.setState({isLoading:true});

    let url1 ="",url2="";
    let newList = [...this.state.commonList];
    const index = newList.findIndex(mnt=>mnt.reference === item.reference)
    if (item.clientConfig){
       url1 = await apiService.get(imgs.configuration.iso.large({reference:item.clientConfig}));
       newList[index].imageClient = url1;
       imgs.configuration.iso.large_with_granites(item.clientConfig).then(data=> {
         this.setState({
           isLoading:false,
           errorMessage: data && data.error? "Impossible de récupérer la liste d'images": ""
         });
       })
    }
    if (item.familyConfig && (item.familyConfig !== item.clientConfig)){
      url2 = await apiService.get(imgs.configuration.iso.large({reference:item.familyConfig}));
      newList[index].imageFamily = url2;
      imgs.configuration.iso.large_with_granites(item.familyConfig).then(data=> {
        this.setState({
          isLoading:false,
          errorMessage: data && data.error? "Impossible de récupérer la liste d'images": ""
        });
      })
    }
    if (url1 || url2){
      this.props.cataActions.load(null,false,true);
      this.setState({ commonList:newList});
    }    
  }

  confirmRefreshAll = (method) =>{
    this.setState({showConfirm: true, methodConfirm: method});
  }
  
  onRespond = (status) =>{
    const method = this.state.methodConfirm
    this.setState({showConfirm: false, methodConfirm: ""});
    if(status && method == "refreshAllGranitsImages"){
      this.refreshAllGranitsImages()
    }else if(status){
      this.refreshAll()
    }
  }

  refreshAll =  async () =>{
    const {preconfList} = this.state;
    this.setState({refreshOngoing:true});
    let confs=[];
    for(let i=0; i< preconfList.length;i++){
      if (preconfList[i].clientConfig){confs.push(preconfList[i].clientConfig)}
      if (preconfList[i].familyConfig && (preconfList[i].familyConfig !==preconfList[i].clientConfig)){
        confs.push(preconfList[i].familyConfig);
      }
    }
    for(let i=0; i< confs.length; i+=1) {
      await apiService.get(imgs.configuration.iso.large({reference:confs[i]}));
    }   
    this.setState({refreshOngoing:false});
  }

  refreshAllGranitsImages =  async () =>{
    const {preconfList} = this.state;
    this.setState({refreshOngoing:true});
    let confs=[];
    for(let i=0; i< preconfList.length;i++){
      if (preconfList[i].clientConfig){confs.push(preconfList[i].clientConfig)}
      // if (preconfList[i].familyConfig && (preconfList[i].familyConfig !==preconfList[i].clientConfig)){
      //   confs.push(preconfList[i].familyConfig);
      // }
    }
    for(let i=0; i< confs.length; i+=1) {
      await imgs.configuration.iso.large_with_granites(confs[i]);
    }   
    this.setState({refreshOngoing:false});
  }
  
  dualDisplayAdd = (type,icon,monument)=><div>
      <i className="icon material-icons header" title={`Preconfiguration ${type}`}>{icon}</i>
      <i className="icon material-icons add single" title={`Ajouter la préconfiguration ${type}`}  onClick={()=>this.addConfig(type,monument)}>add_circle</i>
    </div>

  dualDisplayChange = (type,icon,field,monument)=><div>
    <i className="icon material-icons header" title={`Preconfiguration ${type}`}>{icon}</i>
    <i className="icon material-icons change single" title={`Modifier la préconfiguration ${type}`}  onClick={()=>this.updateConfig(field)}>create</i>
    <i className="icon material-icons delete single" title={`Supprimer la préconfiguration ${type}`}  onClick={()=>this.deleteConfig(field,type,monument)}>delete_forever</i>    
  </div>

  onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex ) {
      const reference = this.state.commonList[oldIndex].reference
      const order = this.state.commonList[newIndex].monumentOrder
      if (order != undefined && order > 0) {
        await this.props.cataActions.changeOrder(reference, order);
        this.combineList();
      }
    }
  };

  changeOrder = async (ref, order) => {
    await this.props.cataActions.changeOrder(ref, order);
    this.combineList();
  };

  resetByAlphabeticalOrder = async (status) => {
    if (status) {
      await this.props.cataActions.resetByAlphabeticalOrder();
      this.combineList();
    }
    this.setState({resetOrderConfirm: false})
  }

  displayIcons = (item) =>{
    if (!item.clientConfig && !item.familyConfig){ //aucune configuration
      return <i className="icon material-icons add below" title="Ajouter la préconfiguration"  onClick={()=>this.setState({selected:item.reference})}>add_circle</i>
    }else if (item.clientConfig && !item.familyConfig){ //cas client seul
      return <div>
        {this.dualDisplayChange("client","add_business",item.clientConfig,item.reference)}
        {this.dualDisplayAdd("famille","family_restroom",item.reference)}
      </div>
    }else if (!item.clientConfig && item.familyConfig){ //cas famille seule
      return <div>
        {this.dualDisplayAdd("client","add_business",item.reference)}
        {this.dualDisplayChange("family","family_restroom",item.familyConfig,item.reference)}
      </div>}
    else if (item.clientConfig && item.familyConfig && item.clientConfig !== item.familyConfig ){ //cas famille seule
      return <div> 
        {this.dualDisplayChange("client","add_business",item.clientConfig,item.reference)}
        {this.dualDisplayChange("family","family_restroom",item.familyConfig,item.reference)}
      </div>      
    }else if (item.clientConfig && item.familyConfig && item.clientConfig === item.familyConfig ){
     return <div>
        <i className="icon material-icons header" title={"Famille et client"}>add_business</i>
        <i className="icon material-icons change below " title={`Modifier la préconfiguration `}  onClick={()=>this.updateConfig(item.clientConfig)}>create</i>
        <i className="icon material-icons delete below" title={`Supprimer la préconfiguration `}  onClick={()=>this.deleteConfig(item.clientConfig,"both",item.reference)}>delete_forever</i>   
        <div><i className="icon material-icons header" title={"Famille et client"}>family_restroom</i> </div>
    </div>
    }
  }
  
  // handleOnSelectCatalog = async (item) =>{
  //   const catalog = item? (item.value? 'catalog='+item.value: ''): ''
  //   this.setState({catalogSelected: (item? item: {})}) 
  //   apiService.get(`/api/admin/monuments?${catalog}`).then(preconfList => this.setState({preconfList, forceMethodUpdate:true}))
  // }


  render(){
    const { commonList,selected,refreshOngoing } = this.state;
    const {catalog} = this.props;
    let visibility = "unset"
    // Check is the call called by iframe admin
    if(window.location.href.includes("adminCatalog")){
      visibility = "hidden"
    }

    return (
      <div>
        {this.state.errorMessage && <div className="error" style={{padding: '20px', backgroundColor: '#f44336', color: 'white', fontSize: '15px', fontWeight: 'bold'}}>{this.state.errorMessage}</div>
        }
        {this.state.isLoading && <div className="container" style={{textAlign: 'center', paddingTop: '160px'}}>
            <img src={loader} alt="Chargement" />
          </div>
        }
        {!this.state.isLoading && <div>
            <div className="adminPreconfig">
              <button className="AdminFamilyBack" onClick={() => history.push("/")} style={{visibility: visibility}}>
                <i className="icon material-icons AdminFamilyBackArrow">home</i>Accueil
              </button>
              {/* <div className="" style={{width: '180px', display: "inline-block", margin: "5px"}}>
                <Select 
                  options={ this.state.catalogs.map(catalog=> ({ value: catalog.internal_id, label: catalog.name })) }
                  // defaultValue={this.state.catalogSelected}
                  value={this.state.catalogSelected}
                  className="FAuto-select" 
                  placeholder="Choisir un Catalog" 
                  onChange={this.handleOnSelectCatalog} 
                  // isClearable
                />
              </div> */}
              <button className="AdminFamilyRefreshAll" onClick={() => this.confirmRefreshAll("refreshAll")}>Tout rafraichir</button>
              <button className="AdminFamilyRefreshAll" onClick={() => this.confirmRefreshAll("refreshAllGranitsImages")}>Tout rafraichir (Tous les granits)</button>
              <button className="AdminFamilyRefreshAll" onClick={() => this.setState({resetOrderConfirm: true})}>Reinitialiser par ordre alphabetique</button>
              <Modal isOpen={refreshOngoing} className="refreshAll">Veuillez patienter pendant la regénération des images (durée environ 20mn)</Modal>
              <SearchBox onSearch={this.onSearch}  onFilter={this.onFilter} catalog={this.props.catalog} noScroll={true} featured={true}/>

              <SortableMonuments commonList={commonList} selected={selected} get_image={this.getImage} axis="xy" disabled={!!catalog.search}
                                disp_icons={this.displayIcons} add_config={this.addConfig}  onSortEnd={this.onSortEnd}  
                                distance={2} refresh_image = {this.refreshImage} change_order= {this.changeOrder}/>
            </div>
          </div>
        }
        <PopupConfirm
          isOpen={this.state.showConfirm}
          className='ModalElemConfirm'
          centered={true}
          fade={true}
          title={'Confirmez votre choix'}
          message={
            'Attention ceci va effacer tous les caches et recréer tous les monuments ceci peut prendre du temps à ne faire que si cache Lumiscpahe vidé. Si souci sur un monument rafrîchir individuellement. Cliquez sur CONTINUER pour confirmer votre choix.'
          }
          eventName={'confirm-delete-accessories'}
          respond={ this.onRespond }
        ></PopupConfirm>
        {/* Confiration alphabetical order */}
        <PopupConfirm
          isOpen={this.state.resetOrderConfirm} className='ModalElemConfirm' centered={true} fade={true}
          title={'Confirmez votre choix'}
          message={"L'ordre des monuments va être réinitialisé par ordre alphanumérique. Etes-vous sûr de vouloir continuer ?"}
          respond={ this.resetByAlphabeticalOrder }
        ></PopupConfirm>
      </div>
    )
  }
}
export const AdminCatalog = connect(
  state => ({
    adminConfig: state.adminConfig,
    catalog:state.catalog
  }),(dispatch) => ({
    actions: bindActionCreators(configurationActions, dispatch),
    cataActions: bindActionCreators(catalogActions, dispatch),
  })
  )(AdminCatalogComponent);
