import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import history from '../../history'
import { FengravingPattern } from './FengravingPattern'
import { FaccessoryParams } from './FaccessoryParams'
import { Ffooter } from './Ffooter'
import FTopBar from './FTopBar'
import {Fheader} from "./Fheader";
import { OvalAction } from '../../components/OvalAction'
import MediaQuery from 'react-responsive/src'
import { NotifUpdate } from "../configurator/components/NotifUpdate";
import { gtmFamily } from '../../services/gtmFamily';

const detectiOS = () => {
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return iOS;
}

export const Faccessory = ({ match, designType }) => {
  const configurator = useSelector((state) => state.configurator)
  const dispatch = useDispatch()

  const config = configurator.current && configurator.current.configuration
  const noAcc = config && config.accessories.length === 0 ? true : false
  const [accSel, setAccSel] = useState(noAcc)

  function goCustomize() {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "revenir à ma configuration",
      category: "configurateur",
      subcategory: "configuration",
      type: "cta",
      from: "configuration accessoire"
    }, { template: "configurateur", subtemplate: "liste accessoires" }, {}, "partner", "product", "user", "page")
    // end GTM
    const { clientId, configId } = match.params
    history.push(`/famille/${clientId}/${configId}/customize`)
  }
  function closeAccSelector() {
    setAccSel(false)
  }

  const addAccessoryMobileAction = () => {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "ajouter des accessoires",
      category: "configurateur",
      subcategory: "liste accessoires",
      type: "cta",
      from: "configuration accessoire"
    }, { template: "configurateur", subtemplate: "accessoire" })
    // end GTM
    setAccSel(true)
  }

  if (detectiOS()){
    document.body.style.setProperty("background", "unset", "important");
    const elems = document.getElementsByClassName('AccessoriesZone');
    if (elems && elems.length){
      elems[0].style.setProperty("top", "20vh", "important");
    }
  }
  return (
    <div>
      <NotifUpdate />
      {designType==="A" && <Fheader match={match} /> }
      {designType === 'B' && (
        <FTopBar goCustomize={() => goCustomize()} from='FAccessory' />
      )}
      <FengravingPattern
        match={match}
        mode='accessory'
        backAction={() => goCustomize()}
      />
      <MediaQuery minWidth={768}>
        <FaccessoryParams
          backAction={() => goCustomize()}
          close={() => closeAccSelector()}
        />
      </MediaQuery>
      <MediaQuery maxWidth={768}>
        {accSel && (
          <FaccessoryParams
            backAction={() => goCustomize()}
            close={() => closeAccSelector()}
          />
        )}
        {!accSel && (
          <OvalAction
            action={() => addAccessoryMobileAction()}
            text={'Ajouter des accessoires'}
            frontIcon='add'
            addClass='accessoryPlus Plus'
          />
        )}
      </MediaQuery>
      {designType === 'A' && (
        <Ffooter
          previous={() => history.push(`./fmotif`)}
          next={() => history.push(`./ffinalisation`)}
          addClass='Wide'
        />
      )}
    </div>
  )
}
