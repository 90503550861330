import React from 'react';
import {typeEngraving, typePattern, typeAccessory} from './Fabric';
import {canDuplicate} from '../../../services/restrictedConfiguration';
import * as _ from 'lodash';
import { isConfigRequested } from '../../../services/configurationWarningChecks';
import {isSmartphone} from '../../../services/domFunctions';
import graniteImage from '../../../images/Groupe_297.svg';

/**
 * Barre d'actions flottante accroché en bas de l'objet sélectionné
 */
export function FabricActionBar({
  left, top,family,
  configuration, selectedObjects, onRemoveObjects, onDuplicateObject, onEditEngraving,
  onMirrorPattern, onToggleEngravingReservation, onChangeAccessoryGranite, context:t
}) {
  
  if (!selectedObjects || !selectedObjects.length) {
    return null;
  }

  let buttons = [];
  let disabled = isConfigRequested();
  let classDisabled = disabled ? "disabled" : ""

  if (selectedObjects.length > 1) {
    buttons.push((
      <button key="remove" onClick={onRemoveObjects} title="Supprimer tous ces éléments" className={classDisabled} disabled={disabled}>
        <i className="icon material-icons">&#xE872;</i>
      </button>
    ));
  } else {
    const selected = selectedObjects[0];
    const theClass = family ? "ButtonFabricFamily": "";
    buttons.push((
      <button className={`${theClass} ${classDisabled}`} 
        key="remove" 
        onClick={onRemoveObjects} 
        title= { !family ? t('config3d_placement_tooltip_delete') : "Supprimer cet élément" } 
        disabled={disabled}>    
        {family ? (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6H5H21" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 11V17" stroke="#8A8A8A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>):
                  (<i className="icon material-icons">&#xE872;</i>)}
      </button>
    ));
    canDuplicate(configuration,selected.type,family) && buttons.push((
      <button key="duplicate" onClick={onDuplicateObject} title={ t('config3d_placement_tooltip_duplicate') || "Dupliquer cet élément" } className={classDisabled} disabled={disabled}>
        <i className="icon material-icons">&#xE145;</i>
      </button>
    ));


      switch (selected.type) {
        case typePattern:
          if (!family){  buttons.push((
            <button key="mirror" onClick={onMirrorPattern} title={t('config3d_placement_tooltip_horizontal-flip') || "Miroir horizontal"} className={classDisabled} disabled={disabled}>
              <i className="icon material-icons">&#xE3E8;</i>
            </button>
          ));}
          break;

        case typeEngraving:
            if (!family){
              const engraving = _.find(configuration.engravings, {id: selected.id});
              buttons.push((
                <button key="toggleReservation" onClick={onToggleEngravingReservation} className={classDisabled}
                        title={engraving.reservation 
                          ? t('config3d_placement_tooltip_reservation-on') || "Passer en gravure réelle" 
                          : t('config3d_placement_tooltip_reservation-off') || "Passer en réserve de gravure"
                        }
                        disabled={disabled}>
                  {engraving.reservation ? (
                    <i className="icon material-icons">&#xE883;</i>
                  ) : (
                    <i className="icon material-icons">&#xE882;</i>
                  )}
                </button>
              ));
              }else{
                if (isSmartphone()){
                  buttons.push((
                    <button className={theClass} key="edit" onClick={onEditEngraving} title="Modifier cet élément">    
                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.06 0.589883L17.41 1.93988C18.2 2.71988 18.2 3.98988 17.41 4.76988L4.18 17.9999H0V13.8199L10.4 3.40988L13.23 0.589883C14.01 -0.190117 15.28 -0.190117 16.06 0.589883ZM2 15.9999L3.41 16.0599L13.23 6.22988L11.82 4.81988L2 14.6399V15.9999Z" fill="#8a8a8a"/>
                      </svg>
                    </button>
                  ));
                }
              }
          break;

        case typeAccessory:
           buttons.push((
            <button className={`${theClass} ${classDisabled}`} 
                    key="granite" onClick={onChangeAccessoryGranite} 
                    title={ !family ? t('config3d_placement_tooltip_granit') : "Modifier le granit" } 
                    disabled={disabled}>
                  {family ? (<img src={graniteImage} alt="entrée"/>):
                  (<i className="icon material-icons">&#xE3AE;</i>)}             
            </button>
          ));
          break;
        default:
          break;
      }
  }
  
  return (
    <div className="FabricActionBar" style={{left, top}}>
      {buttons}
    </div>
  );
}