import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {LazyImage} from '../../../components/LazyImage';
import { displayStandardConfigImage } from '../../../services/image.fct.service';

/**
 * Composant affichant la vue iso d'une configuration
 */
class ConfigurationIsoViewComponent extends Component {
  static propTypes = {
    isoView: PropTypes.shape({
      previous: PropTypes.string,
      current: PropTypes.string,
    }),
  };

  state = {
    showPrevious: false, // A l'initialisation, on ne veut pas de l'ancienne image pour éviter un "flash"
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.isoView !== this.props.isoView) {
      // On a modifié la vue alors qu'elle était déjà affichée.
      // Dans ce cas, on va indiquer au composant LazyImage
      // l'image lowRes
      this.setState({
        showPrevious: true
      });
    }
  };

  render() {
    const {isoView,config} = this.props;
    return (
      <div className="ConfigurationIsoView">
        {(config.monument.reference !== "ACC" || !isoView.loading) && (
        <LazyImage className="FullSize Monument background-page" lazy={false}
                   //src={isoView.current}
                   src={isoView.current} 
                   lowResImage={this.state.showPrevious && isoView.loading && isoView.previous}
                    frame={this.props.frame}
        />
        )}
        {isoView.loading && (
          <p className="LoadingMessage">Regénération du visuel en cours</p>
        )}
      </div>
    )
  }
}

export const ConfigurationIsoView = connect(
  (state) => ({
    isoView: state.isoView,
  })
)(ConfigurationIsoViewComponent);
