import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as uiActions from '../../../actions/uiActions';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import {ModalButton} from '../../../components/ModalButton';
import {ScrollListView} from '../../../components/ScrollListView';
import {AccessoryCard} from './AccessoryCard';
import * as _ from 'lodash';
// import {kinds} from '../ConfiguratorAccessory';
import { isConfigRequested } from '../../../services/configurationWarningChecks'
import { TranslationContext } from "../../../context/TranslationContext";

/**
 * ModalButton permettant de choisir un accessoire
 */
class AccessoryPickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    configuration: PropTypes.shape({
      monument: PropTypes.shape({
        graniteMain: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    kind: PropTypes.string.isRequired,
    allGranites: PropTypes.arrayOf(PropTypes.shape({
      reference: PropTypes.string.isRequired,
      madeInFrance: PropTypes.bool,
    })),
  };

  state;
  modal;

  constructor(props) {
    super(props);
    this.state = {
      drawing: props.drawing,
      kind: props.kind,
      maxAcc: 12
    };
  }

  onSelectAccessory = (accessory) => granite => {
    const accessories = this.props.configuration.accessories;
    const {maxAcc} = this.state;
    if (accessories && accessories.length < maxAcc){
      this.props.actions.addAccessory(accessory, granite);
    this.modal.close();
    }else{
      alert("Vous ne pouvez ajouter que 12 accessoires au maximum.")
    }
  };

  onClickDisabled = () => {
    this.props.uiActions.addMessage({
      id: 'accessories-picker-unavailable',
      className: 'warning',
      innerHTML: 'Aucun accessoire pour le monument dans ce granit'
    })
  };

  renderItem = (madeInFrance) => (accessory) => {
    return (
      <AccessoryCard key={accessory.reference}
                     configuration={this.props.configuration}
                     accessory={accessory}
                     onSelectAccessory={this.onSelectAccessory(accessory)}
                     madeInFrance={madeInFrance}
      />
    );
  };

  render() {
    const t = this.context;
    const kinds = {
      'Vase': {
        id: 'Vase',
        label: t("config3d_accessories_button_vase") || "Vases",
      },
      'Jardinière': {
        id: 'Jardinière',
        label: t("config3d_accessories_button_planter") || "Jardinières",
      },
      'Divers': {
        id: 'Divers',
        label: t("config3d_accessories_button_other") || "Autres...",
      },
    };
    const {configuration, allAccessories, allGranites} = this.props;

    const fullMonumentGranite = _.find(allGranites, {reference: configuration.monument.graniteMain});
    const isMonumentMadeInFrance = fullMonumentGranite && fullMonumentGranite.madeInFrance;
    const kind = this.state.kind;
    let accessories = []
    

    if (configuration.monument.kind === 'ACC'){
      accessories = _.chain(allAccessories)
      .filter({kind: kind})
      .filter(accessory=> _.some(accessory.granites, {madeInFranceAndImported: true}) || (accessory.granites && accessory.granites.length > 0))
      .value();
    }else{
      console.log("allAccessories", allAccessories)
      console.log("isMonumentMadeInFrance", isMonumentMadeInFrance)
      accessories = _.chain(allAccessories)
      .filter({kind: kind})
      .filter(accessory => _.some(accessory.granites, {madeInFranceAndImported: true}) || _.some(accessory.granites, {madeInFrance: isMonumentMadeInFrance}))
      .value();
      console.log("accessories", accessories)
    }
    if (accessories.length) {
      return (
        <div className="btn-wrapper">
          <ModalButton ref={(_modal) => this.modal = _modal} buttonContent={kinds[this.props.kind].label}
                      buttonIcon={this.props.buttonIcon}
                      buttonClassName={` ${this.props.buttonClassName ? this.props.buttonClassName : ''}`} 
                      disabled={!accessories.length || isConfigRequested()}
                      className="buttonModal background-page"
                      toggleSteps={this.props.toggleSteps}
                      launchIntro={this.props.launchIntro}
                      madalName={this.props.madalName}                  
                      >
            <div className="Header">
              <div className="HeaderContainer">
                <h1>
                  { t("config3d_accessories_modal_title") || "Sélectionnez un accessoire" }
                </h1>
                <span className="maxSpan">
                  { t("config3d_accessories_modal_subtitle") || "(12 au maximum)" }
                </span>
              </div>
            </div>

            <div className="buttonsContainer buttons-group">
              <button className={`kindButton ${kind==='Vase' ? 'selectedKind' : ''}`} onClick={() => this.setState({kind: "Vase"})}><i className="icon icon-accessory-vase" /> <span>Vases</span></button>
              <button className={`kindButton ${kind==='Jardinière' ? 'selectedKind' : ''}`} onClick={() => this.setState({kind: "Jardinière"})}><i className="icon icon-accessory-box" /> <span>Jardinières</span></button>
              <button className={`kindButton ${kind==='Divers' ? 'selectedKind' : ''}`} onClick={() => this.setState({kind: "Divers"})} ><i className="icon icon-accessories-misc"/><span>Autres</span></button>
            </div>

            <ScrollListView items={accessories} renderItem={this.renderItem(isMonumentMadeInFrance)} className="AccessoryPicker"/>
          </ModalButton>
        </div>
      )
    } else {
      return (
        <div className="btn-wrapper">
          <div className="btn-config">
            <button className="SquareButton btn-config-tertiary secondary2 text-light disabled"
                    title="Aucun accessoire pour le monument dans ce granit"
                    onClick={this.onClickDisabled}>
              <span className={`${this.props.buttonIcon } icon icon-large`}></span>
              <div>{kinds[this.props.kind].label}</div>
            </button>
          </div>
        </div>
      )
    }
  }
}

export const AccessoryPicker = connect((state, ownProps) => ({
    configuration: state.configurator.current.configuration,
    allAccessories: state.configurator.accessories,
    allGranites: state.configurator.current.options.granites,
    catalog: state.catalog,
  }),
  (dispatch) => ({
    uiActions: bindActionCreators(uiActions, dispatch),
    actions: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(AccessoryPickerComponent);
